import { configure, LEVEL, enable } from "./loggerService";
import { LoggerServiceBuilder } from "./interface";

const NAMESPACE = "mindfulpayments";

const defaultLogger = (level: LEVEL): LoggerServiceBuilder =>
  configure(NAMESPACE, level);

export default {
  NAMESPACE,
  defaultLogger,
  configure,
  enable,
  LEVEL,
};
