import React, { useState, useEffect } from "react";
import styled from "styled-components";
import TextField from "@material-ui/core/TextField";
import { makeStyles } from "@material-ui/core/styles";
import { colors } from "modules/utils/colors";
import useOnClickOutside from "use-onclickoutside";
import EditIcon from "@material-ui/icons/Edit";

const IconEdit = styled(EditIcon)`
  color: ${colors.authSecondaryFontColor};
  font-size: 22px !important;
  position: absolute;
  bottom: 10px;
  right: 13px;
`;

interface InputWrapperProps
  extends React.DetailedHTMLProps<
    React.HTMLAttributes<HTMLDivElement>,
    HTMLDivElement
  > {
  marginBottom?: string;
}

const InputWrapper = styled.div<InputWrapperProps>`
  position: relative;
  display: flex;
  margin-bottom: ${(props) => (props.marginBottom ? props.marginBottom : "0")};
  position: relative;
  flex-direction: column;
`;

const InputLabel = styled.div`
  font-size: 16px;
  color: ${colors.authInputColor};
  text-align: left;
  margin-bottom: 5px;
`;

const InputLabelSmall = styled.div`
  color: ${colors.authInputColor};
  text-align: left;
  font-size: 12px;
  margin-bottom: 2px;
`;

interface ErrorMessageProps
  extends React.DetailedHTMLProps<
    React.HTMLAttributes<HTMLSpanElement>,
    HTMLSpanElement
  > {
  bottom?: string;
}

const ErrorMessage = styled.span<ErrorMessageProps>`
  font-size: 12px;
  color: ${colors.mainFont};
  width: 100%;
  background-color: ${colors.error};
  position: absolute;
  bottom: ${(props) => (props.bottom ? props.bottom : "-25px")};
  padding: 5px 5px;
  border-radius: 5px;
  box-sizing: border-box;
  text-align: left;
`;

interface TextFieldProps {
  readonly inputId: string;
  readonly value: string;
  readonly label: string;
  readonly onChange: (value: string) => void;
  readonly onBlur?: (value: string) => void;
  readonly error?: string;
  readonly type?: string;
  readonly heightInput?: string;
  readonly placeholder?: string;
  readonly errorBottom?: string;
  readonly marginBottom?: string;
  readonly smallLabel?: boolean;
  readonly disabled?: boolean;
  readonly icon?: boolean;
  readonly required?: boolean;
  readonly fontSizeInput?: number;
}

export const TextFieldTransparent: React.FC<TextFieldProps> = ({
  inputId,
  value,
  onChange,
  onBlur = () => {},
  error,
  label,
  type,
  heightInput,
  errorBottom,
  smallLabel,
  fontSizeInput,
  placeholder,
  marginBottom,
  disabled,
  required,
  icon,
}) => {
  const [active, setActive] = useState(false);
  const [inputLabel, setInputLabel] = useState(label);

  const InputStyles = makeStyles({
    root: {
      overflow: "hidden",
      borderRadius: 4,

      backgroundColor: "transparent",
      "& .MuiOutlinedInput-root": {
        color: colors.authInputColor,
        height: heightInput ? heightInput : "30px",
        fontSize: fontSizeInput ? fontSizeInput : 16,
        border: `1px solid ${
          error === "ok" ? colors.active : `${colors.authInputColor}`
        }`,
        "&:hover fieldset": {
          border: `1px solid ${colors.authOutline}`,
        },
        "&.Mui-focused fieldset": {
          border: `1px solid ${colors.authOutline}`,
        },
      },
    },
  });
  const classes = InputStyles();

  const inputRef = React.useRef<HTMLInputElement>(null);

  useOnClickOutside(inputRef, (e) => {
    e.preventDefault();
    setActive(false);
  });

  useEffect(() => {
    if (active && inputRef.current) {
      inputRef.current && inputRef.current.focus();
    }
  }, [active]);

  useEffect(() => {
    if (required) {
      setInputLabel(inputLabel + " *");
    }
  }, []);

  return (
    <InputWrapper marginBottom={marginBottom}>
      {smallLabel ? (
        <InputLabelSmall>{inputLabel}</InputLabelSmall>
      ) : (
        <InputLabel>{inputLabel}</InputLabel>
      )}
      <TextField
        id={inputId}
        onClick={() => {
          setActive(true);
        }}
        classes={{
          root: classes.root,
        }}
        disabled={disabled}
        onBlur={(e) => onBlur(e.target.value)}
        value={value}
        onChange={(e) => onChange(e.target.value)}
        variant="outlined"
        size={heightInput ? "small" : "medium"}
        type={type ? type : "text"}
        error={!!error && error !== "ok"}
        placeholder={placeholder || ""}
      />
      {!!error && error !== "ok" && (
        <ErrorMessage bottom={errorBottom}>{error}</ErrorMessage>
      )}
      {icon && <IconEdit />}
    </InputWrapper>
  );
};
