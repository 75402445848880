import { API } from "config";
import { APIRequest } from "./interfaces";
import { FailedResponse, SuccessResponse } from "modules/utils/response";
import type { Menu } from "modules/menu";

import { logger } from "./logger";
import { deserialize } from "./menuSerializer";

const log = logger.extend("getMenuListAsync");

interface Params {
  id: string;
  revalidate?: boolean;
}

/**
 * Api Service for fetching Menu instance by id.
 *
 * @param {string} id - Id of Menu.
 * @param {boolean | undefined} revalidate - Flag for revalidation. It
 *  sets Cache-Control to "no-cache" for current request. Default:
 *  false.
 * @return {Promise<FailedResponse | SuccessResponse<Menu>>}
 */
export const getMenuAsync: APIRequest<Params, Menu> = async ({
  id,
  revalidate = false,
}) => {
  log.info("Fetching menu `%s`.", id);

  const response = await fetch(API.menu.entity(id), {
    method: "GET",
    headers: {
      "Content-Type": "application/json",
      Accept: "application/json",
      "Cache-Control": revalidate ? "no-cache" : "private",
      Authorization: `JWT ${await localStorage.getItem("token")}`,
    },
  });
  log.debug("Response %O", response);

  if (response.status === 200) {
    const dto = await response.json();
    log.debug("Fetched data: %O", dto);
    const menu = deserialize(dto);
    return new SuccessResponse<Menu>(menu);
  }

  log.info("Error while fetching `%s`.", id);
  return new FailedResponse(await response.json());
};
