import { API } from "config";
import { APIRequest } from "./interfaces";
import { FailedResponse, SuccessResponse } from "modules/utils/response";
import type { Menu, APIMenuDto } from "modules/menu";
import { deserialize } from "./menuSerializer";
import { logger } from "./logger";
import { UserProfile } from "./interfaces";

const log = logger.extend("getMenuListAsync");

interface RestaurantData {
  menu_list: Menu[];
  profile_data: UserProfile;
}

/**
 * Api Service for fetch active menu of the restaurant;
 *
 * @param {string} slug - Slug that tell us which restaurant should display active menu
 * @return {Promise<SuccessResponse<RestaurantData> | FailedResponse>}
 */
export const getRestaurantMenu: APIRequest<string, RestaurantData> = async (slug) => {
  log.info("Fetch active menu");
  const response = await fetch(API.menu.getActiveMenu(slug), {
    method: "GET",
    headers: {
      "Content-Type": "application/json",
      Accept: "application/json",
    },
  });
  log.debug("Response %O", response);

  if (response.status === 200) {
    const dto = await response.json();
    const profileData = dto.profile_data;
    const menuList = dto.menu_list.map((menu: APIMenuDto) => deserialize(menu));
    log.debug("Fetched data: %O", dto);
    return new SuccessResponse<RestaurantData>({
      menu_list: menuList,
      profile_data: profileData,
    });
  }

  log.warn("Error while getting active menu.");
  return new FailedResponse(await response.json());
};
