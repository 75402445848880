import { API } from "config";
import { APIRequest } from "./interfaces";
import { FailedResponse, SuccessResponse } from "modules/utils/response";
import type { Menu } from "modules/menu";
import { deserializeList } from "./menuSerializer";
import { logger } from "./logger";

const log = logger.extend("getMenuListAsync");

interface Filters {
  revalidate?: boolean;
}

/**
 * Api Service for fetching list of Menus.
 *
 * @param {boolean | undefined} revalidate - Flag for revalidation. It
 *  sets Cache-Control to "no-cache" for current request. Default:
 *  false.
 * @return {Promise<SuccessResponse<Menu[]> | FailedResponse>}
 */
export const getMenuListAsync: APIRequest<Filters, Menu[]> = async ({
  revalidate = false,
} = {}) => {
  log.info("Fetching menu list.");
  const response = await fetch(API.menu.list(), {
    method: "GET",
    headers: {
      "Content-Type": "application/json",
      Accept: "application/json",
      "Cache-Control": revalidate ? "no-cache" : "private",
      Authorization: `JWT ${await localStorage.getItem("token")}`,
    },
  });

  log.debug("Response %O", response);

  if (response.status === 200) {
    log.info("Successfully got menu list.");

    const rawData = await response.json();
    log.debug("Fetched data: %O", rawData);

    const menuList = deserializeList(rawData);

    return new SuccessResponse<Menu[]>(menuList);
  }

  log.warn("Error while fetching menu list.");
  return new FailedResponse(await response.json());
};
