import {
  postMenuAsync,
  putMenuAsync,
  deleteMenuAsync,
  getMenuListAsync,
  getMenuAsync,
} from "services/apiService";
import type { Menu } from "./model";
import type { Response } from "modules/utils/response";

export const getMenu = async (id: string): Promise<Response<Menu>> => {
  return await getMenuAsync({ id });
};

export const getAllMenus = async (revalidate = false): Promise<Response<Menu[]>> => {
  return await getMenuListAsync({ revalidate });
};

export const updateMenu = async (
  menu: Menu,
  active: boolean
): Promise<Response<{ id: string }>> => {
  return await putMenuAsync({ menu, active });
};

export const deactivateAllOtherMenus = async (menu: Menu): Promise<void> => {
  const activeMenusResponse = await getAllMenus();
  if (activeMenusResponse.success) {
    const activeMenusAndCategories = activeMenusResponse
      .getData()
      .filter((activeMenu) => activeMenu.getId() !== menu.getId())
      .filter(
        (activeMenu) =>
          activeMenu.getProp("menuCategory") === menu.getProp("menuCategory")
      );
    await Promise.all(
      activeMenusAndCategories.map((activeMenu) => activeMenu.setInactive(true, true))
    );
  }
};

export const saveMenuAsync = async (menu: Menu): Promise<Response<{ id: string }>> => {
  if (menu.isNew()) {
    return await postMenuAsync({ menu });
  }
  return await putMenuAsync({ menu, active: false });
};

export const removeMenuAsync = async (menu: Menu): Promise<Response> => {
  return await deleteMenuAsync({ menu });
};

export const getSectionKey = (index: number): string => `section[${index}]`;

export const getSpecialSectionKey = (index: number): string => `special[${index}]`;

export const getSectionIndexFromKey = (sectionKey: string): number => {
  const match = /section\[(\d+)\]*/g.exec(sectionKey);
  if (match) {
    return parseInt(match[1]);
  }
  return -1;
};

export const getSpecialSectionIndexFromKey = (sectionKey: string): number => {
  const match = /special\[(\d+)\]*/g.exec(sectionKey);
  if (match) {
    return parseInt(match[1]);
  }
  return -1;
};

export const getEntryKey = (sectionKey: string, index: number): string =>
  `${sectionKey}-entry[${index}]`;

export const getSpecialEntryKey = (sectionKey: string, index: number): string =>
  `${sectionKey}-entry[${index}]`;

export const getEntryIndexFromKey = (sectionKey: string): number => {
  const match = /section\[\d\]-entry\[(\d+)\]*/g.exec(sectionKey);
  if (match) {
    return parseInt(match[1]);
  }
  return -1;
};

export const getSpecialEntryIndexFromKey = (sectionKey: string): number => {
  const match = /special\[\d\]-entry\[(\d+)\]*/g.exec(sectionKey);
  if (match) {
    return parseInt(match[1]);
  }
  return -1;
};
