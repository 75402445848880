import I from "immutable";

// eslint-disable-next-line @typescript-eslint/ban-ts-comment
// @ts-ignore
export interface ITypedMap<T> extends I.Map<string, unknown> {
  get<K extends keyof T>(name: K): T[K];
}

export function TypedMap<T>(value: T): ITypedMap<T> {
  return (I.Map(value) as unknown) as ITypedMap<T>;
}
