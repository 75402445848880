export const colors = {
  mainBackground: "#2B2C3A",
  cardBackground: "#3E3F4C",
  fullCardBackground: "#3E3F4C",
  mainButton: "#6B69F9",
  mainBorder: "#D8D8D8",
  cardBorder: "rgba(128,134,139,0.59)",
  hoverBackgroundEdit: "#D8D8D8",
  secondaryBorder: "#979797",
  tertiaryBorder: "rgba(151, 151, 151, 0.4)",
  placeholder: "rgba(151, 151, 151, 0.4)",
  mainFont: "#FFFFFF",
  disabledButton: "#dbdbdb",
  disabledButtonFont: "#acacac",
  darkFont: "#343644",
  darkButton: "#2B2C3A",
  popperBackground: "#FFFFFF",
  secondaryFont: "#D8D8D8",
  tabUnused: "#b0b0b0",
  active: "#A5FF02",
  placeholderLight: "#AAAAAA",
  unActiveTab: "#45475A",
  unActive: "#C30000",
  cardIconBackground: "#000000",
  activeCardColor: "#6B69F9",
  activeCardColorDark: "#5755fa",
  disabledColor: "#858585",
  separatingLine: "rgba(151,151,151,0.28)",
  triangleColor: "rgba(151,151,151,0.28)",
  focusSection: "rgba(123, 166, 245, 0.5)",
  error: "#C30000",
  // authentication
  authMainFontColor: "#6F6EFF",
  authSecondaryFontColor: "#fff",
  authBorder: "rgba(128,134,139,0.59)",
  btnShadow: "rgba(0,0,0,0.59)",
  authIconColor: "#80868B",
  authBackground: "#6F6EFF",
  authPaymentPriceBackground: "#8c8bff",
  authActiveIconColor: "#6F6EFF",
  authFillIconColor: "#2B2C3A",
  authIconsBackground: "#80868B",
  authInputColor: "#fff",
  authInputBorder: "#fff",
  authSignUpBackground: "#5059fe",
  authOutline: "#359BE0",
  authSubmitBtn: "#2B2C3A",
  authTimePickerBackground: "#5D5CF8",
  authSeparateLine: "#4a4a4a",
  authTermsBackground: "#3D3F52",
};

export enum ColorPicker {
  "#000000",
  "#FFFFFF",
  "#6b6b6b",
  "#5D2CF8",
  "#E91E63",
  "#5D9CF8",
  "#9C27B0",
  "#355BE0",
  "#3949AB",
  "#5069fe",
  "#3D7F52",
  "#039BE5",
  "#5D1CF1",
  "#00ACC1",
  "#00897B",
  "#4CAF50",
  "#C0CA33",
  "#FBC02D",
  "#E65100",
  "#E53935",
  "#4E342E",
  "#DFBD70",
  "#BA8F4C",
}
