import React from "react";
import { useEffect, useState } from "react";
import { Menu, MenuEvent } from "../model";
import { getAllMenus } from "../services";
import { logger } from "../logger";
import { useHistory } from "react-router-dom";
import { menuCategoriesEnum } from "modules/utils/const";
import { ProfileContext } from "modules/authentication/register/context";
import _ from "lodash";

const log = logger.extend("useMenuList");

interface MenuListByCategories {
  food: Menu[];
  lunch: Menu[];
  alcohols: Menu[];
  soda: Menu[];
  child: Menu[];
  christmas: Menu[];
}

const initialMenuList: MenuListByCategories = {
  food: [],
  lunch: [],
  alcohols: [],
  soda: [],
  child: [],
  christmas: [],
};

interface UseMenuListReturn {
  readonly processing: boolean;
  readonly hash: number;
  readonly menuList: Menu[];
  readonly menuFilteredByCategories: MenuListByCategories;
  readonly toggleMenuActivity: (menu: Menu) => void;
  readonly updateName: (menu: Menu, name: string) => void;
  readonly removeMenu: (menu: Menu) => Promise<void>;
  readonly copyMenu: (menu: Menu) => Promise<void>;
  readonly addNewMenu: () => void;
}
type UseMenuListHook = () => UseMenuListReturn;

export const useMenuList: UseMenuListHook = () => {
  const [processing, setProcessing] = useState<boolean>(false);
  const [hash, setHash] = useState<number>(0);
  const [menuList, setMenuList] = useState<Menu[]>([]);
  const [menuFilteredByCategories, setMenuFilteredByCategories] = useState(
    initialMenuList
  );
  const { menuCategory } = React.useContext(ProfileContext);
  const history = useHistory();

  const fetchList = async (revalidate = false) => {
    const response = await getAllMenus(revalidate);
    if (response.success) {
      setMenuList(response.getData());
      menuCategoriesSegregation(response.getData());
    }
  };

  useEffect(() => {
    fetchList(true);
  }, []);

  const calculateHash = (menus: Menu[]) => {
    setHash(menus.map((menu) => menu.hash).reduce((sum, hash) => sum + hash, 0));
  };

  const menuCategoriesSegregation = (menuList: Menu[]) => {
    const menuCategoriesList = _.cloneDeep(initialMenuList);
    menuList.forEach((menu: Menu) => {
      const category = menu.getProp("menuCategory");
      const categoryName = menuCategoriesEnum[category];
      _.get(menuCategoriesList, categoryName).push(menu);
    });
    setMenuFilteredByCategories(menuCategoriesList);
  };

  useEffect(() => {
    const changedListeners = menuList.map((menu) =>
      menu.on(MenuEvent.CHANGED, () => calculateHash(menuList))
    );
    const changedRemoteListeners = menuList.map((menu) =>
      menu.on(MenuEvent.CHANGED_REMOTE, () => fetchList(true))
    );
    return () => {
      changedListeners.forEach((listener) => listener.unsubscribe());
      changedRemoteListeners.forEach((listener) => listener.unsubscribe());
    };
  }, [menuList]);

  useEffect(() => {
    calculateHash(menuList);
  }, [menuList]);

  const toggleMenuActivity = async (menu: Menu) => {
    if (processing) {
      log.info("Cannot toggle while is processing another request.");
      return;
    }

    if (!menu.getProp("active")) {
      menuList.forEach((menu) => menu.setInactive(false, false));
    }
    setProcessing(true);
    await menu.toggleActive();
    setProcessing(false);
  };

  const updateName = (menu: Menu, name: string) => {
    menu.updateName(name);
  };

  const removeMenu = async (menu: Menu): Promise<void> => {
    await menu.remove();
  };

  const copyMenu = async (menu: Menu): Promise<void> => {
    const newMenu = new Menu({
      ...menu.toDto(),
      name: `Copy of ${menu.getProp("name")}`,
      active: false,
    });
    await newMenu.save();
    fetchList(true);
  };

  const addNewMenu = () => {
    history.push(`/admin/edit/${menuCategory}/new`);
  };

  return {
    processing,
    hash,
    menuList,
    toggleMenuActivity,
    updateName,
    removeMenu,
    copyMenu,
    addNewMenu,
    menuFilteredByCategories,
  };
};
