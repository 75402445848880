import { API } from "config";
import { APIRequest } from "./interfaces";
import { FailedResponse, SuccessResponse } from "modules/utils/response";
import { logger } from "./logger";

interface Params {
  email: string;
}

const log = logger.extend("resetPassword");

/**
 * Api Service for reset user password
 *
 * @param email - Email.
 * @return {Promise<FailedResponse | SuccessResponse<null>>}
 */
export const resetPasswordAsync: APIRequest<Params> = async ({ email }) => {
  log.info("Reset user password user `%s`.", email);

  const response = await fetch(API.auth.resetPassword(), {
    method: "POST",
    headers: {
      "Content-Type": "application/json",
      Accept: "application/json",
    },
    body: JSON.stringify({ email }),
  });

  log.debug("Response %O", response);

  if (response.status === 200) {
    log.info("Successfully reset user password user `%s`.", email);
    return new SuccessResponse(null);
  }
  log.warn("Error while resetting  user password `%s`.", email);
  return new FailedResponse(await response.json());
};
