import { useContext } from "react";
import { MenuContext } from "../contexts/menuContext";
import { Menu } from "../model";

interface MenuContextReturn {
  menu: Menu | null;
  updateContext: (menu: Menu) => void;
}
type UseMenuContextHook = () => MenuContextReturn;

export const useMenuContext: UseMenuContextHook = () => {
  const { menu, update } = useContext(MenuContext);

  return {
    menu,
    updateContext: update,
  };
};
