import React from "react";

import { lang } from "modules/utils/translations";
import { IconWrapper, Logo, Icon } from "./styles";

export const LogoApp: React.FC = () => {
  const language = lang.navigation;
  return (
    <Logo>
      <IconWrapper>
        <Icon />
      </IconWrapper>
      {language.logoTitle}
    </Logo>
  );
};
