import { API } from "config";
import { logger } from "./logger";

const log = logger.extend("getPaymentAgreement");

/**
 * Api Service for fetching payment agreement.
 *  false.
 * @return {Promise<Blob>}
 */

export const getPaymentAgreementFileAsync = async (): Promise<Blob> => {
  log.info("Fetching qr payment agreement file.");
  const response = await fetch(API.files.paymentAgreement(), {
    method: "GET",
    headers: {
      "Content-Type": "application/json",
      Accept: "application/json",
      Authorization: `JWT ${await localStorage.getItem("token")}`,
    },
  });

  log.debug("Response %O", response);

  if (response.status === 200) {
    log.info("Successfully got payment agreement file.");
    const rawData = response.blob();
    log.debug("Fetched data: %O", rawData);
    return rawData;
  }

  log.warn("Error while fetching payment agreement file.");
  return await response.json();
};
