import React, { useState, useEffect } from "react";
import styled from "styled-components";
import TextField from "@material-ui/core/TextField";
import { makeStyles, withStyles } from "@material-ui/core/styles";
import { colors } from "../../utils/colors";
import useOnClickOutside from "use-onclickoutside";
import HelpIcon from "@material-ui/icons/Help";
import Tooltip from "@material-ui/core/Tooltip";

const LightTooltip = withStyles(() => ({
  tooltip: {
    backgroundColor: colors.popperBackground,
    color: "black",
    fontSize: 11,
  },
}))(Tooltip);

const IconHelp = styled(HelpIcon)`
  color: ${colors.mainButton};
  margin-left: 10px;
  font-size: 16px !important;
  cursor: pointer;
`;

const EmptyInput = styled.input`
  position: absolute;
  z-index: -10000;
  opacity: 0;
`;

const InputLabel = styled.div`
  font-size: 16px;
  color: ${colors.authInputColor};
  text-align: left;
`;

const InputLabelWrapper = styled.div`
  display: flex;
  align-items: center;
  margin-bottom: 2px;
`;

const InputLabelSmall = styled.div`
  color: ${colors.authInputColor};
  text-align: left;
  font-size: 12px;
`;

interface ErrorMessageProps
  extends React.DetailedHTMLProps<
    React.HTMLAttributes<HTMLSpanElement>,
    HTMLSpanElement
  > {
  bottom?: string;
}

const ErrorMessage = styled.span<ErrorMessageProps>`
  font-size: 12px;
  color: ${colors.mainFont};
  width: 100%;
  background-color: ${colors.error};
  position: absolute;
  bottom: ${(props) => (props.bottom ? props.bottom : "-25px")};
  padding: 5px 5px;
  border-radius: 5px;
  box-sizing: border-box;
  text-align: left;
`;

interface TextFieldProps {
  readonly inputId: string;
  readonly value: string;
  readonly label: string;
  readonly onChange: (value: string) => void;
  readonly onBlur?: (value: string) => void;
  readonly error?: string;
  readonly type?: string;
  readonly heightInput?: string;
  readonly placeholder?: string;
  readonly errorBottom?: string;
  readonly smallLabel?: boolean;
  readonly required?: boolean;
  readonly forceFocus?: boolean;
  readonly fontSizeInput?: number;
  readonly questionMarkLabel?: boolean;
  readonly tooltipText?: string;
}

export const TextFieldAuth: React.FC<TextFieldProps> = ({
  inputId,
  value,
  onChange,
  onBlur = () => {},
  error,
  label,
  type,
  heightInput,
  errorBottom,
  smallLabel,
  fontSizeInput,
  placeholder,
  forceFocus,
  required,
  questionMarkLabel,
  tooltipText = "",
}) => {
  const [active, setActive] = useState(false);
  const [inputLabel, setInputLabel] = useState(label);

  const InputStyles = makeStyles({
    root: {
      overflow: "hidden",
      borderRadius: 4,

      backgroundColor: colors.authInputColor,
      "& .MuiOutlinedInput-root": {
        height: heightInput ? heightInput : "30px",
        fontSize: fontSizeInput ? fontSizeInput : 16,
        border: `2px solid ${error === "ok" ? colors.active : "unset"}`,
        "&:hover fieldset": {
          border: `2px solid ${colors.authOutline}`,
        },
        "&.Mui-focused fieldset": {
          border: `2px solid ${colors.authOutline}`,
        },
      },
    },
  });
  const classes = InputStyles();

  const inputRef = React.useRef<HTMLInputElement>(null);
  const inputEmptyRef = React.useRef<HTMLInputElement>(null);

  useOnClickOutside(inputRef, (e) => {
    e.preventDefault();
    setActive(false);
  });

  useEffect(() => {
    if (active && inputRef.current && forceFocus) {
      inputRef.current && inputRef.current.focus();
    } else if (!active && inputEmptyRef.current && forceFocus) {
      inputEmptyRef.current.focus();
    }
  }, [active]);

  useEffect(() => {
    if (required) {
      setInputLabel(inputLabel + " *");
    }
  }, []);

  return (
    <>
      {smallLabel ? (
        <InputLabelWrapper>
          <InputLabelSmall>{inputLabel}</InputLabelSmall>
          {questionMarkLabel && (
            <LightTooltip title={tooltipText} placement="right">
              <IconHelp />
            </LightTooltip>
          )}
        </InputLabelWrapper>
      ) : (
        <InputLabelWrapper>
          <InputLabel>{inputLabel}</InputLabel>
          {questionMarkLabel && (
            <LightTooltip title={tooltipText} placement="right">
              <IconHelp />
            </LightTooltip>
          )}
        </InputLabelWrapper>
      )}
      <TextField
        id={inputId}
        onClick={() => {
          setActive(true);
        }}
        classes={{
          root: classes.root,
        }}
        onBlur={(e) => onBlur(e.target.value)}
        inputRef={forceFocus ? inputRef : null}
        value={value}
        onChange={(e) => onChange(e.target.value)}
        variant="outlined"
        size={heightInput ? "small" : "medium"}
        type={type ? type : "text"}
        error={!!error && error !== "ok"}
        placeholder={placeholder || ""}
      />
      {!!error && error !== "ok" && (
        <ErrorMessage bottom={errorBottom}>{error}</ErrorMessage>
      )}
      {forceFocus && <EmptyInput ref={inputEmptyRef} />}
    </>
  );
};
