import { API } from "config";
import { APIRequest } from "./interfaces";
import { FailedResponse, SuccessResponse } from "modules/utils/response";
import { logger } from "./logger";
import { FormDataSignature } from "modules/authentication/register/signature/_formAgreement";
import { serialize } from "object-to-formdata";

interface Params {
  dataForm: FormDataSignature;
}

const log = logger.extend("Signature user");

/**
 * Api Service for complete terms with signature
 *
 * @param dataForm - signature payment info.
 * @return {Promise<FailedResponse | SuccessResponse<null>>}
 */

export const postUserSignatureAsync: APIRequest<Params> = async ({ dataForm }) => {
  log.info("Send signature user");

  const data = serialize({
    ...dataForm,
  });
  const response = await fetch(API.auth.signatureData(), {
    method: "POST",
    headers: {
      Authorization: `JWT ${await localStorage.getItem("token")}`,
    },
    body: data,
  });

  log.debug("Response %O", response);

  if (response.status === 200) {
    log.info("Successfully sended signature");
    return new SuccessResponse(null);
  }
  log.warn("Error while sending signature");
  return new FailedResponse(await response.json());
};
