import Debug from "debug";
import { LoggerService, LoggerServiceBuilder } from "./interface";

export enum LEVEL {
  DEBUG,
  INFO,
  WARN,
  ERROR,
  NONE,
}

/**
 * Set given key to browser `localStorage` to enable LoggerService
 *  printouts being written to console.
 *
 * @param mainNamespace - Main mainNamespace of logger.
 * @param subNamespaces - Detailed subNamespaces of subnamespaces
 *  filters.
 */
export const enable = (mainNamespace: string, subNamespaces = "*"): void => {
  if (localStorage) {
    localStorage.setItem("debug", `${mainNamespace}:${subNamespaces}`);
  } else {
    console.warn("No `localStorage` found");
  }
};

/**
 * LoggerService configurator.
 *
 * Return LoggerServiceBuilder.
 *
 * @param mainNamespace - Logger main namespace.
 * @param level - Log level.
 * @return {LoggerServiceBuilder} - Builder which return LoggerService
 *  for given subnamespace.
 */
export const configure = (
  mainNamespace: string,
  level: LEVEL = LEVEL.NONE
): LoggerServiceBuilder => {
  const _debug = Debug(mainNamespace);
  const _info = Debug(mainNamespace);
  const _warn = Debug(mainNamespace);
  const _error = Debug(mainNamespace);

  if (level <= LEVEL.DEBUG) {
    _debug.log = console.debug.bind(console);
  } else {
    _debug.log = () => {};
  }

  if (level <= LEVEL.INFO) {
    _info.log = console.log.bind(console);
  } else {
    _info.log = () => {};
  }

  if (level <= LEVEL.WARN) {
    _warn.log = console.warn.bind(console);
  } else {
    _warn.log = () => {};
  }

  if (level <= LEVEL.ERROR) {
    _error.log = console.error.bind(console);
  } else {
    _error.log = () => {};
  }

  return _getLogger(_debug, _info, _warn, _error);
};

const _getLogger = (
  _debug: Debug.Debugger,
  _info: Debug.Debugger,
  _warn: Debug.Debugger,
  _error: Debug.Debugger
) => (subNamespace: string): LoggerService => {
  const debug = _debug.extend(subNamespace);
  const info = _info.extend(subNamespace);
  const warn = _warn.extend(subNamespace);
  const error = _error.extend(subNamespace);

  return {
    debug,
    info,
    warn,
    error,
    extend: _getLogger(debug, info, warn, error),
  };
};
