import React from "react";
import Popper from "@material-ui/core/Popper";
import styled from "styled-components";
import { colors, ColorPicker } from "modules/utils/colors";
import pepper from "modules/ui/assets/chili-pepper.svg";
import { lang } from "modules/utils/translations";
import useOnClickOutside from "use-onclickoutside";

import selected from "modules/ui/assets/selected-icon.svg";
import { allergensArray, AllergensImages } from "modules/utils/const";
// import Tooltip from "@material-ui/core/Tooltip";
// import { withStyles } from "@material-ui/core";

// const LightTooltip = withStyles(() => ({
//   tooltip: {
//     backgroundColor: colors.secondaryBorder,
//     color: "black",
//     fontSize: 14,
//     marginBottom: -5,
//   },
// }))(Tooltip);

const SelectPopper = styled.div`
  font-size: 14px;
  padding: 10px;
  background-color: ${colors.popperBackground};
  border-radius: 5px;
  position: relative;
  cursor: pointer;
  min-height: 18px;
`;

const ArrowDown = styled.div`
  width: 0;
  height: 0;
  border-left: 7px solid transparent;
  border-right: 7px solid transparent;
  position: absolute;
  right: 10px;
  top: 16px;
  border-top: 7px solid ${colors.triangleColor};
`;

const ArrowUp = styled.div`
  width: 0;
  height: 0;
  border-left: 7px solid transparent;
  border-right: 7px solid transparent;
  border-bottom: 7px solid ${colors.triangleColor};
  position: absolute;
  right: 10px;
  top: 16px;
`;

const PopperContent = styled.div`
  background-color: ${colors.popperBackground};
  border-bottom-left-radius: 5px;
  border-bottom-right-radius: 5px;
  overflow: hidden;
  width: 250px;
  position: absolute;
  left: -125px;
  top: -4px;
`;

const HoverElement = styled.div`
  cursor: pointer;
  height: 36px;
  display: flex;
  padding-left: 10px;
  font-size: 14px;
  align-items: center;
  &:hover {
    background: ${colors.hoverBackgroundEdit};
  }
`;

const Img = styled.img`
  width: 15px;
  height: 15px;
`;

const Wrapper = styled.div`
  margin: 20px 0;
`;

const Label = styled.span`
  color: ${colors.authInputColor};
  font-size: 12px;
  margin-bottom: 2px;
`;

interface DropDownProps {
  readonly children: React.ReactNode;
  readonly text: string;
  readonly label: string;
}

export const DropDown: React.FC<DropDownProps> = ({ text, children, label }) => {
  const [anchorEl, setAnchorEl] = React.useState<null | HTMLElement>(null);

  const handleClick = (event: React.MouseEvent<HTMLElement>) => {
    setAnchorEl(anchorEl ? null : event.currentTarget);
  };

  const open = Boolean(anchorEl);

  return (
    <Wrapper>
      <Label>{label}</Label>
      <SelectPopper onClick={handleClick}>
        {anchorEl ? <ArrowUp /> : <ArrowDown />}
        {text}
      </SelectPopper>
      <Popper open={open} anchorEl={anchorEl} transition>
        <PopperContent>{children}</PopperContent>
      </Popper>
    </Wrapper>
  );
};

interface SelectDropDownProps {
  readonly onChange: (value: number) => void;
  readonly value: number;
}

export const DropDownSpice: React.FC<SelectDropDownProps> = ({ onChange, value }) => {
  const [anchorEl, setAnchorEl] = React.useState<null | HTMLElement>(null);
  const [disableClickOutside, setDisableClickOutside] = React.useState<boolean>(false);
  const popperRef = React.useRef<HTMLDivElement>(null);
  const translations = lang.editTemplate.themeEditor;
  const open = Boolean(anchorEl);

  useOnClickOutside(popperRef, (e) => {
    !disableClickOutside && setAnchorEl(null);
  });

  const handleClick = (event: React.MouseEvent<HTMLElement>) => {
    setDisableClickOutside(false);
    setAnchorEl(anchorEl ? null : event.currentTarget);
  };

  return (
    <Wrapper>
      <Label>{translations.spice}</Label>
      <SelectPopper onClick={handleClick} ref={popperRef}>
        {anchorEl ? <ArrowUp /> : <ArrowDown />}
        {value === 0 && translations.none}
        {value > 0 && <Img src={pepper} alt={"pepper"} />}
        {value > 1 && <Img src={pepper} alt={"pepper"} />}
        {value > 2 && <Img src={pepper} alt={"pepper"} />}
      </SelectPopper>
      <Popper
        open={open}
        anchorEl={anchorEl}
        transition
        onMouseLeave={() => {
          setDisableClickOutside(false);
        }}
        onMouseEnter={() => {
          setDisableClickOutside(true);
        }}
      >
        <PopperContent>
          <HoverElement
            onClick={() => {
              setAnchorEl(null);
              onChange(0);
            }}
          >
            {translations.none}
          </HoverElement>
          <HoverElement
            onClick={() => {
              setAnchorEl(null);
              onChange(1);
            }}
          >
            <Img src={pepper} alt={"pepper"} />
          </HoverElement>
          <HoverElement
            onClick={() => {
              setAnchorEl(null);
              onChange(2);
            }}
          >
            <Img src={pepper} alt={"pepper"} />
            <Img src={pepper} alt={"pepper"} />
          </HoverElement>
          <HoverElement
            onClick={() => {
              setAnchorEl(null);
              onChange(3);
            }}
          >
            <Img src={pepper} alt={"pepper"} />
            <Img src={pepper} alt={"pepper"} />
            <Img src={pepper} alt={"pepper"} />
          </HoverElement>
        </PopperContent>
      </Popper>
    </Wrapper>
  );
};

export const DropDownPrice: React.FC<SelectDropDownProps> = ({ value, onChange }) => {
  const [anchorEl, setAnchorEl] = React.useState<null | HTMLElement>(null);
  const [disableClickOutside, setDisableClickOutside] = React.useState<boolean>(false);
  const popperRef = React.useRef<HTMLDivElement>(null);

  const open = Boolean(anchorEl);
  const translations = lang.editTemplate.themeEditor;
  const handleClick = (event: React.MouseEvent<HTMLElement>) => {
    setAnchorEl(anchorEl ? null : event.currentTarget);
  };

  useOnClickOutside(popperRef, (e) => {
    !disableClickOutside && setAnchorEl(null);
  });

  return (
    <Wrapper>
      <Label>{translations.priceType}</Label>
      <SelectPopper onClick={handleClick} ref={popperRef}>
        {anchorEl ? <ArrowUp /> : <ArrowDown />}
        {value === 1 && translations.single}
        {value === 2 && translations.double}
        {value === 3 && translations.triple}
      </SelectPopper>
      <Popper
        open={open}
        anchorEl={anchorEl}
        transition
        onMouseLeave={() => {
          setDisableClickOutside(false);
        }}
        onMouseEnter={() => {
          setDisableClickOutside(true);
        }}
      >
        <PopperContent>
          <HoverElement
            onClick={() => {
              setAnchorEl(null);
              onChange(1);
            }}
          >
            {translations.single}
          </HoverElement>
          <HoverElement
            onClick={() => {
              setAnchorEl(null);
              onChange(2);
            }}
          >
            {translations.double}
          </HoverElement>
          <HoverElement
            onClick={() => {
              setAnchorEl(null);
              onChange(3);
            }}
          >
            {translations.triple}
          </HoverElement>
        </PopperContent>
      </Popper>
    </Wrapper>
  );
};

const HoverIcon = styled.div`
  cursor: pointer;
  display: flex;
  width: calc(250px / 4);
  position: relative;
  font-size: 14px;
  justify-content: center;
  padding: 5px;
  align-items: center;
  display: flex;
  flex-direction: column;
  box-sizing: border-box;
  &:hover {
    background: ${colors.hoverBackgroundEdit};
  }
`;
const IconAllergen = styled.img`
  height: 25px;
  width: 25px;
`;

const IconSelected = styled.img`
  height: 15px;
  width: 15px;
  position: absolute;
  bottom: 17px;
  right: 5px;
`;

const IconsRow = styled.div`
  display: flex;
`;

const IconsText = styled.div`
  font-size: 12px;
  text-align: center;
  max-width: calc(250px / 4);
`;

const Placeholder = styled.div`
  color: ${colors.disabledColor};
`;

interface SelectAllergensProps {
  readonly onChange: (value: number) => void;
  readonly value: number[];
}

export const DropDownAllergens: React.FC<SelectAllergensProps> = ({
  value,
  onChange,
}) => {
  const [anchorEl, setAnchorEl] = React.useState<null | HTMLElement>(null);
  const [disableClickOutside, setDisableClickOutside] = React.useState<boolean>(false);
  const popperRef = React.useRef<HTMLDivElement>(null);

  const translations = lang.editTemplate.themeEditor;
  const handleClick = (event: React.MouseEvent<HTMLElement>) => {
    setAnchorEl(anchorEl ? null : event.currentTarget);
  };

  useOnClickOutside(popperRef, (e) => {
    !disableClickOutside && setAnchorEl(null);
  });

  const open = Boolean(anchorEl);

  return (
    <Wrapper>
      <Label>{translations.allergens}</Label>
      <SelectPopper onClick={handleClick} ref={popperRef}>
        {anchorEl ? <ArrowUp /> : <ArrowDown />}
        {!value.length && <Placeholder>{translations.select}</Placeholder>}
        {allergensArray.map((imageData: AllergensImages) => {
          return (
            value.indexOf(imageData.id) !== -1 && (
              <Img
                key={imageData.id.toString()}
                src={imageData.image}
                alt={imageData.alt}
              />
            )
          );
        })}
      </SelectPopper>
      <Popper
        open={open}
        anchorEl={anchorEl}
        transition
        onMouseLeave={() => {
          setDisableClickOutside(false);
        }}
        onMouseEnter={() => {
          setDisableClickOutside(true);
        }}
      >
        <PopperContent>
          <IconsRow>
            {allergensArray
              .slice(0, 4)
              .map((imageData: AllergensImages, index: number) => {
                return (
                  <HoverIcon onClick={() => onChange(imageData.id)} key={imageData.alt}>
                    <IconAllergen src={imageData.image} alt={imageData.alt} />
                    {value.indexOf(imageData.id) !== -1 && (
                      <IconSelected src={selected} />
                    )}
                    <IconsText>{imageData.alt}</IconsText>
                  </HoverIcon>
                );
              })}
          </IconsRow>
          <IconsRow>
            {allergensArray
              .slice(4, 8)
              .map((imageData: AllergensImages, index: number) => {
                return (
                  <HoverIcon onClick={() => onChange(imageData.id)} key={imageData.alt}>
                    <IconAllergen src={imageData.image} alt={imageData.alt} />
                    {value.indexOf(imageData.id) !== -1 && (
                      <IconSelected src={selected} />
                    )}
                    <IconsText>{imageData.alt}</IconsText>
                  </HoverIcon>
                );
              })}
          </IconsRow>
          <IconsRow>
            {allergensArray
              .slice(8, 12)
              .map((imageData: AllergensImages, index: number) => {
                return (
                  <HoverIcon onClick={() => onChange(imageData.id)} key={imageData.alt}>
                    <IconAllergen src={imageData.image} alt={imageData.alt} />
                    {value.indexOf(imageData.id) !== -1 && (
                      <IconSelected src={selected} />
                    )}
                    <IconsText>{imageData.alt}</IconsText>
                  </HoverIcon>
                );
              })}
          </IconsRow>
        </PopperContent>
      </Popper>
    </Wrapper>
  );
};

const HoverColor = styled.div`
  cursor: pointer;
  height: 25px;
  display: flex;
  width: calc(250px / 8);
  position: relative;
  font-size: 14px;
  justify-content: center;
  padding: 2px;
  align-items: center;
  &:hover {
    background: ${colors.hoverBackgroundEdit};
  }
`;

const ColorValue = styled.div`
  margin-left: 10px;
  margin-top: 2px;
`;

interface IconColorProps
  extends React.DetailedHTMLProps<
    React.HTMLAttributes<HTMLDivElement>,
    HTMLDivElement
  > {
  color: string;
}
const IconColor = styled.div<IconColorProps>`
  height: 19px;
  width: 19px;
  background: ${(props) => props.color};
  border-radius: 50%;
`;

interface SelectColorDropdown {
  readonly onChange: (value: number) => void;
  readonly value: number;
  readonly label: string;
}

export const DropDownColorPicker: React.FC<SelectColorDropdown> = ({
  value,
  onChange,
  label,
}) => {
  const [anchorEl, setAnchorEl] = React.useState<null | HTMLElement>(null);
  const [disableClickOutside, setDisableClickOutside] = React.useState<boolean>(false);
  const popperRef = React.useRef<HTMLDivElement>(null);

  const handleClick = (event: React.MouseEvent<HTMLElement>) => {
    setAnchorEl(anchorEl ? null : event.currentTarget);
  };

  const change = (value: number) => {
    onChange(value);
    setAnchorEl(null);
  };

  useOnClickOutside(popperRef, (e) => {
    !disableClickOutside && setAnchorEl(null);
  });
  const open = Boolean(anchorEl);
  return (
    <Wrapper>
      <Label>{label}</Label>
      <SelectPopper onClick={handleClick} ref={popperRef}>
        {anchorEl ? <ArrowUp /> : <ArrowDown />}
        <IconsRow>
          <IconColor color={ColorPicker[value]} />
          <ColorValue>{ColorPicker[value]}</ColorValue>
        </IconsRow>
      </SelectPopper>
      <Popper
        open={open}
        anchorEl={anchorEl}
        transition
        onMouseLeave={() => {
          setDisableClickOutside(false);
        }}
        onMouseEnter={() => {
          setDisableClickOutside(true);
        }}
      >
        <PopperContent>
          <IconsRow>
            <HoverColor onClick={() => change(0)}>
              <IconColor color={ColorPicker[0]} />
            </HoverColor>
            <HoverColor onClick={() => change(1)}>
              <IconColor color={ColorPicker[1]} />
            </HoverColor>
            <HoverColor onClick={() => change(2)}>
              <IconColor color={ColorPicker[2]} />
            </HoverColor>
            <HoverColor onClick={() => change(3)}>
              <IconColor color={ColorPicker[3]} />
            </HoverColor>
            <HoverColor onClick={() => change(4)}>
              <IconColor color={ColorPicker[4]} />
            </HoverColor>
            <HoverColor onClick={() => change(5)}>
              <IconColor color={ColorPicker[5]} />
            </HoverColor>
            <HoverColor onClick={() => change(6)}>
              <IconColor color={ColorPicker[6]} />
            </HoverColor>
          </IconsRow>
          <IconsRow>
            <HoverColor onClick={() => change(7)}>
              <IconColor color={ColorPicker[7]} />
            </HoverColor>
            <HoverColor onClick={() => change(8)}>
              <IconColor color={ColorPicker[8]} />
            </HoverColor>
            <HoverColor onClick={() => change(9)}>
              <IconColor color={ColorPicker[9]} />
            </HoverColor>
            <HoverColor onClick={() => change(10)}>
              <IconColor color={ColorPicker[10]} />
            </HoverColor>
            <HoverColor onClick={() => change(11)}>
              <IconColor color={ColorPicker[11]} />
            </HoverColor>
            <HoverColor onClick={() => change(12)}>
              <IconColor color={ColorPicker[12]} />
            </HoverColor>
            <HoverColor onClick={() => change(13)}>
              <IconColor color={ColorPicker[13]} />
            </HoverColor>
          </IconsRow>
          <IconsRow>
            <HoverColor onClick={() => change(14)}>
              <IconColor color={ColorPicker[14]} />
            </HoverColor>
            <HoverColor onClick={() => change(15)}>
              <IconColor color={ColorPicker[15]} />
            </HoverColor>
            <HoverColor onClick={() => change(16)}>
              <IconColor color={ColorPicker[16]} />
            </HoverColor>
            <HoverColor onClick={() => change(17)}>
              <IconColor color={ColorPicker[17]} />
            </HoverColor>
            <HoverColor onClick={() => change(18)}>
              <IconColor color={ColorPicker[18]} />
            </HoverColor>
            <HoverColor onClick={() => change(19)}>
              <IconColor color={ColorPicker[19]} />
            </HoverColor>
            <HoverColor onClick={() => change(20)}>
              <IconColor color={ColorPicker[20]} />
            </HoverColor>
          </IconsRow>
        </PopperContent>
      </Popper>
    </Wrapper>
  );
};

export const DropDownPricePoints: React.FC<SelectDropDownProps> = ({
  value,
  onChange,
}) => {
  const [anchorEl, setAnchorEl] = React.useState<null | HTMLElement>(null);
  const [disableClickOutside, setDisableClickOutside] = React.useState<boolean>(false);
  const popperRef = React.useRef<HTMLDivElement>(null);

  const open = Boolean(anchorEl);
  const translations = lang.editTemplate.themeEditor;
  const handleClick = (event: React.MouseEvent<HTMLElement>) => {
    setAnchorEl(anchorEl ? null : event.currentTarget);
  };

  useOnClickOutside(popperRef, (e) => {
    !disableClickOutside && setAnchorEl(null);
  });

  return (
    <Wrapper>
      <Label>{translations.priceType}</Label>
      <SelectPopper onClick={handleClick} ref={popperRef}>
        {anchorEl ? <ArrowUp /> : <ArrowDown />}
        {value === 2 && translations.decimal}
        {value === 1 && translations.nonDecimal}
      </SelectPopper>
      <Popper
        open={open}
        anchorEl={anchorEl}
        transition
        onMouseLeave={() => {
          setDisableClickOutside(false);
        }}
        onMouseEnter={() => {
          setDisableClickOutside(true);
        }}
      >
        <PopperContent>
          <HoverElement
            onClick={() => {
              setAnchorEl(null);
              onChange(2);
            }}
          >
            {translations.decimal}
          </HoverElement>
          <HoverElement
            onClick={() => {
              setAnchorEl(null);
              onChange(1);
            }}
          >
            {translations.nonDecimal}
          </HoverElement>
        </PopperContent>
      </Popper>
    </Wrapper>
  );
};
