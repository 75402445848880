import React from "react";
import styled from "styled-components";
import { colors } from "../../utils/colors";
import { cardDimensions } from "../../utils/const";

interface TileProps
  extends React.DetailedHTMLProps<
    React.HTMLAttributes<HTMLDivElement>,
    HTMLDivElement
  > {
  readonly center?: boolean;
  readonly withoutHover?: boolean;
  readonly pointer?: boolean;
  readonly small?: boolean;
  readonly active?: boolean;
  readonly borderWhite?: boolean;
}

export const Tile = styled.div<TileProps>`
  display: flex;
  flex-direction: column;
  width: ${({ small }) => (small ? cardDimensions.smallWidth : cardDimensions.width)};
  height: ${({ small }) =>
    small ? cardDimensions.smallHeight : cardDimensions.height};
  background-color: ${colors.fullCardBackground};
  border-radius: ${cardDimensions.borderRadius};
  overflow: hidden;
  margin: ${({ small }) => (small ? "4px" : "10px")};
  position: relative;
  border: 1px solid ${colors.mainBackground};

  cursor: ${({ pointer }) => (pointer ? "pointer" : "default")};

  ${({ active, small }) => {
    if (active) {
      return `
        margin: ${small ? "3px" : "9px"};
        border: 2px solid ${colors.activeCardColor};
      `;
    }
  }}

  ${({ center }) => {
    if (center) {
      return `
      justify-content: center;
      align-items: center;
      `;
    }
  }}

  ${({ center }) => {
    if (center) {
      return `
      justify-content: center;
      align-items: center;
      `;
    }
  }}

  ${({ borderWhite }) => {
    if (borderWhite) {
      return `
        border: 1px solid ${colors.cardBorder};
      `;
    }
  }}

  ${({ borderWhite, withoutHover, small }) => {
    if (withoutHover) {
      return `
        cursor: default;
      `;
    }

    if (!withoutHover && !borderWhite) {
      return `

      &:hover {
        margin: ${small ? "3px" : "9px"};
        border: 2px solid ${colors.activeCardColor};
      }
      `;
    }
  }}

`;
