import React from "react";
import { TimePicker, MuiPickersUtilsProvider } from "@material-ui/pickers";
import DateFnsUtils from "@date-io/date-fns";
import AccessTimeIcon from "@material-ui/icons/AccessTime";
import styled from "styled-components";
import { ThemeProvider } from "@material-ui/styles";
import { createMuiTheme } from "@material-ui/core";

const IconTime = styled(AccessTimeIcon)`
  font-size: 22px !important;
  position: absolute;
  right: 5px;
  top: 4px;
  pointer-events: none;
`;

const TimeWrapper = styled.div`
  position: relative;
  width: fit-content;
  cursor: pointer;
`;

const defaultMaterialTheme = createMuiTheme({
  overrides: {
    MuiInputBase: {
      input: {
        cursor: "pointer",
        background: "white",
        borderRadius: 5,
        height: 30,
        padding: "0 3px !important",
        width: 62,
        fontSize: 14,
      },
    },
  },
});

interface TimePickerComponentProps {
  readonly value: Date;
  readonly onChange: (value: Date) => void;
}

export const TimePickerComponent: React.FC<TimePickerComponentProps> = ({
  value,
  onChange,
}) => {
  return (
    <MuiPickersUtilsProvider utils={DateFnsUtils}>
      <TimeWrapper>
        <ThemeProvider theme={defaultMaterialTheme}>
          <TimePicker
            ampm={false}
            minutesStep={5}
            autoOk
            variant="inline"
            inputVariant="filled"
            value={value}
            invalidDateMessage=""
            InputProps={{
              disableUnderline: true,
            }}
            onChange={(date) => date && onChange(date)}
          />
        </ThemeProvider>
        <IconTime />
      </TimeWrapper>
    </MuiPickersUtilsProvider>
  );
};
