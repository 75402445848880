import { API } from "config";
import { logger } from "./logger";
import { SuccessResponse, FailedResponse } from "modules/utils/response";

const log = logger.extend("getUserProfile");

/**
 * Api Service to skip payment
 * @return {Promise<FailedResponse | SuccessResponse<null>>}
 */

export const getSkipPayment = async (): Promise<
  FailedResponse | SuccessResponse<null>
> => {
  log.info("Skipping payment");

  const response = await fetch(API.auth.skipPayment(), {
    method: "GET",
    headers: {
      "Content-Type": "application/json",
      Authorization: `JWT ${await localStorage.getItem("token")}`,
    },
  });
  log.debug("Response %O", response);

  if (response.status === 200) {
    log.info("Successfully skip payment.");
    return new SuccessResponse(null);
  }

  log.info("Error while skipping payment");
  return new FailedResponse(await response.json());
};
