import React, { Suspense, lazy } from "react";
import { BrowserRouter as Router, Route, Switch, Redirect } from "react-router-dom";
import { Loader } from "./modules/ui/loader";
import { AppWrapper } from "./modules/ui";
import { MenuContextManager } from "./modules/menu/contexts";
import { ProfileContextManager } from "modules/authentication/register/context";

const Restaurant = lazy(() => import("./views/menu/restaurantView"));
const AdminDashboard = lazy(() => import("./views/admin/adminDashboardView"));
const AdminMenuEditor = lazy(() => import("./views/admin/adminMenuEditorView"));
const CompleteData = lazy(() => import("./views/authentication/completeDataView"));
const SignIn = lazy(() => import("./views/authentication/signInView"));
const Terms = lazy(() => import("./views/terms"));
const WrongUrlPage = lazy(() => import("./views/wrongUrlPage"));
const SignUp = lazy(() => import("./views/authentication/signUpView"));
const CompleteSignatureView = lazy(() => import("./views/authentication/signature"));
const SignUpDoneView = lazy(() => import("./views/authentication/signUpDoneView"));
const ChangePasswordView = lazy(() =>
  import("./views/authentication/changePasswordView")
);

interface PrivateRouteProps {
  component: React.ElementType;
  path: string;
  exact?: boolean;
}

export const PrivateRoute: React.SFC<PrivateRouteProps> = ({
  component: Component,
  path,
  exact,
}) => {
  return (
    <Route
      exact={exact}
      path={path}
      render={() => {
        const isAuth = !!localStorage.getItem("token");
        return isAuth ? <Component /> : <Redirect to={{ pathname: "/sign-in" }} />;
      }}
    />
  );
};

const App: React.FC = () => {
  return (
    <AppWrapper>
      <Router>
        <Suspense fallback={<Loader />}>
          <Switch>
            <Redirect exact path="/" to="/sign-in" />
            <Route path="/restaurant" component={Restaurant} />
            <Route exact path="/sign-up" component={SignUp} />
            <Route exact path="/sign-in" component={SignIn} />
            <Route path="/change-password" component={ChangePasswordView} />
            <PrivateRoute exact path="/terms" component={Terms} />
            <PrivateRoute exact path="/sign-up-done" component={SignUpDoneView} />
            <ProfileContextManager>
              <Switch>
                <Route path="/profile" component={CompleteData} />
                <PrivateRoute
                  path="/signature-payment"
                  component={CompleteSignatureView}
                />
                <PrivateRoute exact path="/admin" component={AdminDashboard} />
                <MenuContextManager>
                  <Switch>
                    <PrivateRoute
                      path="/admin/edit/:category/:id"
                      component={AdminMenuEditor}
                    />
                    <Route path="*" component={WrongUrlPage} />
                  </Switch>
                </MenuContextManager>
                <Route path="*" component={WrongUrlPage} />
              </Switch>
            </ProfileContextManager>
            <Route component={WrongUrlPage} />
          </Switch>
        </Suspense>
      </Router>
    </AppWrapper>
  );
};

export default App;
