import { API } from "config";
import { APIRequest } from "./interfaces";
import { FailedResponse, SuccessResponse } from "modules/utils/response";
import { logger } from "./logger";

interface Params {
  email: string;
  password: string;
}

const log = logger.extend("authenticate");

/**
 * Api Service for authenticating user.
 *
 * @param email - Email.
 * @param password - Password.
 * @return {Promise<FailedResponse | SuccessResponse<null>>}
 */
export const authenticateAsync: APIRequest<Params> = async ({ email, password }) => {
  log.info("Authenticating user `%s`.", email);

  const response = await fetch(API.auth.authenticate(), {
    method: "POST",
    headers: {
      "Content-Type": "application/json",
      Accept: "application/json",
    },
    body: JSON.stringify({ email, password }),
  });

  log.debug("Response %O", response);
  if (response.status === 200) {
    const { token } = await response.json();
    await localStorage.setItem("token", token);

    log.info("Successfully authenticated user `%s`.", email);
    return new SuccessResponse(null);
  }
  log.warn("Error while authenticating user `%s`.", email);
  return new FailedResponse(await response.json());
};
