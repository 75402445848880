export const regExpMail = /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;

//Minimum eight characters, at least one uppercase letter, one lowercase letter, one number and one special character:
export const regExpPass = /\S{8,}/;

// CIF + NIF + NIE , to the best of my knowledge :)
export const businessRegex = /^([ABCDEFGHJPQRSUVNW|abcdefghjpqrsuvnw]{1}([0-9]{8}))$|^([XYZxyz]{1})([0-9]{7})([a-zA-Z]{1})$|^([KLMklm]{1})([0-9]{7})([a-zA-Z]{1})|^([0-9]{8})([a-zA-Z]{1})$/;
export const postalCodeRegex = /^([a-zA-z0-9- ]{1,16})$/;
export const phoneRegex = /([0-9]{9}$)/;
export const facebookRegex = /\/(?:https:\/\/)?(?:www\.)?facebook\.com\/([a-zA-Z0-9?=&-_/.]+$)/;
export const instagramRegex = /\/(?:https:\/\/)?(?:www\.)?instagram\.com\/([a-zA-Z0-9-._/]+$)/;
export const twitterRegex = /\/(?:https:\/\/)?(?:www\.)?twitter\.com\/([a-zA-Z0-9-_/]+$)/;

export const ibanRegex = /^([a-zA-Z]{2})([0-9]{26})$/;
export const swiftRegex = /^([a-zA-Z]{8,11})$/;

export const toBase64 = (file: File): Promise<string | ArrayBuffer | null> =>
  new Promise((resolve, reject) => {
    const reader = new FileReader();
    reader.readAsDataURL(file);
    reader.onload = () => resolve(reader.result);
    reader.onerror = (error) => reject(error);
  });

export const pxToRem = (width: number, base = "16"): string => {
  const rem = parseFloat((width / parseInt(base.toString(), 10)).toPrecision(4));
  return `${rem}rem`;
};
