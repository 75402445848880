import { API } from "config";
import { logger } from "./logger";

const log = logger.extend("getQrCode");

/**
 * Api Service for fetching list of pdf qr codes.
 * @return {Promise<Blob>}
 */

export const getQrCodesAsync = async (): Promise<Blob> => {
  log.info("Fetching qr code.");
  const response = await fetch(API.files.qrCodes(), {
    method: "GET",
    headers: {
      "Content-Type": "application/json",
      Accept: "application/json",
      Authorization: `JWT ${await localStorage.getItem("token")}`,
    },
  });

  log.debug("Response %O", response);

  if (response.status === 200) {
    log.info("Successfully got menu qr codes.");
    const rawData = response.blob();
    log.debug("Fetched data: %O", rawData);
    return rawData;
  }

  log.warn("Error while fetching qr codes.");
  return await response.json();
};
