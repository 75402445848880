import React from "react";
import ReactDOM from "react-dom";
import { createMuiTheme, ThemeProvider } from "@material-ui/core/styles";
import App from "./App";
import { GoogleFonts } from "modules/ui/components";
import { colors } from "./modules/utils/colors";
import "./style.css";

const theme = createMuiTheme({
  palette: {
    primary: {
      main: `${colors.mainButton}`,
    },
    secondary: {
      main: `${colors.mainFont}`,
    },
    contrastThreshold: 3,
    tonalOffset: 0.2,
  },
});

ReactDOM.render(
  <React.StrictMode>
    <ThemeProvider theme={theme}>
      <GoogleFonts />
      <App />
    </ThemeProvider>
  </React.StrictMode>,
  document.getElementById("root")
);
