import styled from "styled-components";
import { colors } from "modules/utils/colors";

export const IconWrapper = styled.div`
  width: 28px;
  height: 28px;
  color: ${colors.mainBackground};
  background: ${colors.mainButton};
  display: flex;
  justify-content: center;
  align-items: center;
  border-radius: 3px;
  margin-right: 10px;
`;
