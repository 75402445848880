import { menuCategoriesEnum } from "modules/utils/const";
import { MenuData } from "modules/menu/model";
import _ from "lodash";

import menuFood from "./menus/menuFood.json";
import menuLunch from "./menus/menuLunch.json";
import menuSoda from "./menus/menuSoda.json";
import menuAlcohols from "./menus/menuAlcohols.json";
import menuChild from "./menus/menuChild.json";
import menuChristmas from "./menus/menuChristmas.json";
import menuEmpty from "./menus/menuEmpty.json";

type Record<K extends keyof any, T> = { [P in K]: T };

export const menuSelect = (menuCategory: number, isMenuSpecial?: boolean): any => {
  const mapMenus: { [key: number]: Partial<MenuData> } = Object.freeze({
    [menuCategoriesEnum.food]: menuFood,
    [menuCategoriesEnum.lunch]: menuLunch,
    [menuCategoriesEnum.soda]: menuSoda,
    [menuCategoriesEnum.alcohols]: menuAlcohols,
    [menuCategoriesEnum.child]: menuChild,
    [menuCategoriesEnum.christmas]: menuChristmas,
  });

  const menu = mapMenus[menuCategory];
  if (!_.isUndefined(menu)) {
    return menu;
  } else {
    return menuEmpty;
  }
};
