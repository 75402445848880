import { API } from "config";
import { APIRequest } from "./interfaces";
import { FailedResponse, SuccessResponse } from "modules/utils/response";
import { logger } from "./logger";

interface Params {
  email: string;
  password: string;
  repeatPassword: string;
}

const log = logger.extend("register user");

/**
 * Api Service for register user
 *
 * @param email - Email.
 * @param password - Password.
 * @param repeatPassword - Repeated password.
 * @return {Promise<FailedResponse | SuccessResponse<null>>}
 */
export const registerUserAsync: APIRequest<Params> = async ({
  email,
  password,
  repeatPassword,
}) => {
  log.info("Registering user `%s`.", email);

  const response = await fetch(API.auth.registerUser(), {
    method: "POST",
    headers: {
      "Content-Type": "application/json",
      Accept: "application/json",
    },
    body: JSON.stringify({ email, password, password_repeat: repeatPassword }),
  });

  log.debug("Response %O", response);

  if (response.status === 200) {
    log.info("Successfully registered user `%s`.", email);
    return new SuccessResponse(null);
  }
  log.warn("Error while register user `%s`.", email);
  return new FailedResponse(await response.json());
};
