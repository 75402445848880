import loggerService from "./services/loggerService";

const level =
  loggerService.LEVEL[
    // eslint-disable-next-line @typescript-eslint/ban-ts-comment
    // @ts-ignore
    process.env.NODE_ENV !== "production" ? "DEBUG" : process.env.LOGLEVEL || "WARN"
  ];

loggerService.enable(loggerService.NAMESPACE);
export const Logger = loggerService.defaultLogger(level);
