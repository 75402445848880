import { API } from "config";
import { APIRequest } from "./interfaces";
import { FailedResponse, SuccessResponse } from "modules/utils/response";
import { logger } from "./logger";

interface Params {
  password: string;
  repeatPassword: string;
  token: string;
  email: string;
}

const log = logger.extend("resetPassword");

/**
 * Api Service for change user password
 * @param url - Key.
 * @param repeatPassword - Repeated password.
 * @param password - Password.
 * @return {Promise<FailedResponse | SuccessResponse<null>>}
 */
export const changePasswordAsync: APIRequest<Params> = async ({
  password,
  repeatPassword,
  token,
  email,
}) => {
  log.info("Change user password user");

  const response = await fetch(API.auth.changePassword(), {
    method: "POST",
    headers: {
      "Content-Type": "application/json",
      Accept: "application/json",
    },
    body: JSON.stringify({ password, password_repeat: repeatPassword, token, email }),
  });

  log.debug("Response %O", response);

  if (response.status === 200) {
    log.info("Successfully change user password user");
    return new SuccessResponse(null);
  }
  log.warn("Error while changing  user password");
  return new FailedResponse(await response.json());
};
