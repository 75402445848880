import { API } from "config";
import { APIRequest } from "./interfaces";
import type { Menu } from "modules/menu";
import { FailedResponse, SuccessResponse } from "modules/utils/response";
import { logger } from "./logger";

interface Params {
  menu: Menu;
  active: boolean;
}

interface Data {
  id: string;
}

const log = logger.extend("putMenuAsync");

/**
 * Api Service for putting new version of Menu.
 *
 * @param {Menu} menu - Menu instance.
 * @param {Boolean} active - Menu is active.
 * @return {Promise<FailedResponse | SuccessResponse<null>>}
 */
export const putMenuAsync: APIRequest<Params, Data> = async ({ menu, active }) => {
  const menuId = menu.getId();

  if (!menuId) {
    throw new Error("Missing id in Menu entity.");
  }

  log.info("Putting menu `%s`.", menuId);
  const data = menu.toDto();
  const type = menu.getEtag();
  log.debug("Data to put %O.", data);
  const response = await fetch(API.menu.update(menuId), {
    method: "PUT",
    headers: {
      "Content-Type": "application/json",
      Accept: "application/json",
      Authorization: `JWT ${await localStorage.getItem("token")}`,
    },
    body: JSON.stringify({
      data: data,
      type: type,
      active: active,
      category: data.menuCategory,
    }),
  });

  if (response.status === 200) {
    const rawData = await response.json();
    const id = rawData._id;
    log.info("Successfully put menu `%s`.", menuId);
    return new SuccessResponse({ id });
  }

  log.warn("Error while putting menu `%s`.", menuId);
  return new FailedResponse(await response.json());
};
