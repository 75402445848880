import hipster_1_top from "modules/ui/templatesUiAssets/hipster_1_top.svg";
import hipster_1_bottom from "modules/ui/templatesUiAssets/hipster_1_bottom.svg";
import hipster_2_top from "modules/ui/templatesUiAssets/hipster_2_top.svg";
import hipster_2_bottom from "modules/ui/templatesUiAssets/hipster_2_bottom.svg";
import hipster_3_top from "modules/ui/templatesUiAssets/hipster_3_top.svg";
import hipster_3_bottom from "modules/ui/templatesUiAssets/hipster_3_bottom.svg";
import hipster_4_top from "modules/ui/templatesUiAssets/hipster_4_top.svg";
import hipster_4_bottom from "modules/ui/templatesUiAssets/hipster_4_bottom.svg";
import hipster_5_top from "modules/ui/templatesUiAssets/hipster_5_top.svg";
import hipster_5_bottom from "modules/ui/templatesUiAssets/hipster_5_bottom.svg";
import hipster_6_top from "modules/ui/templatesUiAssets/hipster_6_top.svg";
import hipster_6_bottom from "modules/ui/templatesUiAssets/hipster_6_bottom.svg";
import hipster_7_top from "modules/ui/templatesUiAssets/hipster_7_top.svg";
import hipster_7_bottom from "modules/ui/templatesUiAssets/hipster_7_bottom.svg";
import fancy_1_universal from "modules/ui/templatesUiAssets/fancy_1_universal.svg";
import fancy_2_top from "modules/ui/templatesUiAssets/fancy_2_top.svg";
import fancy_2_bottom from "modules/ui/templatesUiAssets/fancy_2_bottom.svg";
import fancy_3_universal from "modules/ui/templatesUiAssets/fancy_3_universal.svg";
import fancy_4_top from "modules/ui/templatesUiAssets/fancy_4_top.svg";
import fancy_4_bottom from "modules/ui/templatesUiAssets/fancy_4_bottom.svg";
import fancy_5_top from "modules/ui/templatesUiAssets/fancy_5_top.svg";
import fancy_5_bottom from "modules/ui/templatesUiAssets/fancy_5_bottom.svg";
import fancy_8_top from "modules/ui/templatesUiAssets/fancy_8_top.svg";
import fancy_8_bottom from "modules/ui/templatesUiAssets/fancy_8_bottom.svg";
import christmas_1_top from "modules/ui/templatesUiAssets/christmas_1_top.svg";
import christmas_1_bottom from "modules/ui/templatesUiAssets/christmas_1_bottom.svg";
import christmas_2_top from "modules/ui/templatesUiAssets/christmas_2_top.svg";
import christmas_2_bottom from "modules/ui/templatesUiAssets/christmas_2_bottom.svg";

export const templatesAssets = Object.freeze({
  hipster1: {
    top: hipster_1_top,
    bottom: hipster_1_bottom,
  },
  hipster2: {
    top: hipster_2_top,
    bottom: hipster_2_bottom,
  },
  hipster3: {
    top: hipster_3_top,
    bottom: hipster_3_bottom,
  },
  hipster4: {
    top: hipster_4_top,
    bottom: hipster_4_bottom,
  },
  hipster5: {
    top: hipster_5_top,
    bottom: hipster_5_bottom,
  },
  hipster6: {
    top: hipster_6_top,
    bottom: hipster_6_bottom,
  },
  hipster7: {
    top: hipster_7_top,
    bottom: hipster_7_bottom,
  },
  fancy1: {
    top: fancy_1_universal,
    bottom: fancy_1_universal,
  },
  fancy2: {
    top: fancy_2_top,
    bottom: fancy_2_bottom,
  },
  fancy3: {
    top: fancy_3_universal,
    bottom: fancy_3_universal,
  },
  fancy4: {
    top: fancy_4_top,
    bottom: fancy_4_bottom,
  },
  fancy5: {
    top: fancy_5_top,
    bottom: fancy_5_bottom,
  },
  fancy8: {
    top: fancy_8_top,
    bottom: fancy_8_bottom,
  },
  christmas1: {
    top: christmas_1_top,
    bottom: christmas_1_bottom,
  },
  christmas2: {
    top: christmas_2_top,
    bottom: christmas_2_bottom,
  },
});
