import React, { useEffect } from "react";
import { UserProfile } from "services/apiService/interfaces";
import { getUserProfile } from "services/apiService";

interface MenuContextValue {
  profile: UserProfile | null;
  profileNotFilled: null | boolean;
  profileExpired: null | boolean;
  updateProfile: () => void;
  clearProfile: () => void;
  updateMenuCategory: (value: number) => void;
  menuCategory: number;
}

export const ProfileContext = React.createContext<MenuContextValue>({
  profile: null,
  profileNotFilled: null,
  profileExpired: null,
  updateProfile: () => {},
  clearProfile: () => {},
  updateMenuCategory: () => {},
  menuCategory: 1,
});

const getSafeData = (profile: UserProfile): UserProfile => {
  return {
    address: profile.address || "",
    business_number: profile.business_number || "0",
    city: profile.city || "",
    facebook_url: profile.facebook_url || "",
    instagram_url: profile.instagram_url || "",
    is_trial_period:
      profile.is_trial_period === undefined ? true : profile.is_trial_period,
    logo: profile.logo || "",
    name: profile.name || "Restaurant",
    opening_hours: profile.opening_hours || "",
    phone_number: profile.phone_number || "",
    public_phone_number: profile.public_phone_number || "",
    public_email: profile.public_email || "",
    use_whatsapp: Boolean(profile.use_whatsapp),
    postal_code: profile.postal_code || "",
    qr_codes_pdf: profile.qr_codes_pdf || "",
    slug: profile.slug || "",
    trial_period_days: profile.trial_period_days || 0,
    twitter_url: profile.twitter_url || "",
    payment_agreement:
      profile.payment_agreement === undefined ? false : profile.payment_agreement,
  };
};

export const ProfileContextManager: React.FC = ({ children }) => {
  const [profile, setProfile] = React.useState<UserProfile | null>(null);
  const [menuCategory, setMenuCategory] = React.useState<number>(1);
  const [profileNotFilled, setProfileNotFilled] = React.useState<boolean | null>(null);
  const [profileExpired, setProfileExpired] = React.useState<boolean | null>(null);

  useEffect(() => {
    setProfile(null);
    updateProfile();
  }, []);

  useEffect(() => {
    if (profile && profile.is_trial_period) {
      if (profile.trial_period_days > 0) {
        setProfileExpired(false);
      } else {
        setProfileExpired(true);
      }
    }
  }, [profile]);

  const clearProfile = () => {
    setProfileNotFilled(null);
    setProfile(null);
  };

  const updateMenuCategory = (category: number) => {
    setMenuCategory(category);
  };

  const updateProfile = () => {
    if (localStorage.getItem("token")) {
      getUserProfile()
        .then((userProfile: UserProfile) => {
          const data = getSafeData(userProfile);
          setProfile(data);
          !userProfile.address ? setProfileNotFilled(true) : setProfileNotFilled(false);
        })
        .catch(() => {
          setProfileNotFilled(true);
        });
    }
  };

  return (
    <ProfileContext.Provider
      value={{
        profile,
        profileNotFilled,
        profileExpired,
        updateProfile,
        clearProfile,
        updateMenuCategory,
        menuCategory,
      }}
    >
      {children}
    </ProfileContext.Provider>
  );
};
