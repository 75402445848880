import React from "react";
import styled from "styled-components";
import { colors } from "../utils/colors";

interface SectionTitleProps {
  readonly title: string;
  readonly tagline?: string;
}

const Wrapper = styled.div`
  margin: 16px 10px 0;
  display: flex;
  flex-direction: column;
`;

const Title = styled.span`
  color: ${colors.mainFont};
  font-size: 22px;
  margin: 5px 0;
`;

const Tagline = styled.span`
  color: ${colors.secondaryFont};
  font-size: 11px;
  text-transform: uppercase;
`;

export const SectionHeadline: React.FC<SectionTitleProps> = ({ title, tagline }) => (
  <Wrapper>
    {tagline && <Tagline>{tagline}</Tagline>}
    <Title>{title}</Title>
  </Wrapper>
);
