import { Menu } from "modules/menu";
import type { MenuDto, APIMenuDto } from "modules/menu";

/**
 * Serialize Menu instance.
 *
 * @param {Menu} instance - Menu instance.
 * @return {MenuDto}
 */
export const serialize = (instance: Menu): MenuDto => {
  return instance.toDto();
};

/**
 * Deserialize Menu from DTO.
 *
 * @param {APIMenuDto} dto - Menu DTO.
 * @return {Menu}
 */
export const deserialize = (dto: APIMenuDto): Menu => {
  return Menu.fromDto(dto);
};

/**
 * Serialize list of Menu instances.
 *
 * @param {Menu[]} instances - List of Menu instances.
 * @return {MenuDto[]}
 */
export const serializeList = (instances: Menu[]): MenuDto[] => {
  return instances.map(serialize);
};

/**
 * Deserialize list of Menus from list of DTO.
 *
 * @param {APIMenuDto[]} dtos - List of DTO.
 * @return {Menu[]}
 */
export const deserializeList = (dtos: APIMenuDto[]): Menu[] => {
  return dtos.map(deserialize);
};
