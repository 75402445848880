import Button from "@material-ui/core/Button";
import { withStyles } from "@material-ui/core/styles";
import { colors } from "../../utils/colors";

export const DarkButton = withStyles(() => ({
  root: {
    color: colors.authInputColor,
    backgroundColor: colors.darkButton,
    padding: "10px 30px",
    height: 40,
    "&:hover": {
      backgroundColor: colors.authSubmitBtn,
    },
    "&.Mui-disabled": {
      backgroundColor: colors.disabledButton,
      color: colors.disabledButtonFont,
    },
  },
}))(Button);
