import * as React from "react";
import { Menu } from "../model";

interface MenuContextValue {
  menu: Menu | null;
  update: (menu: Menu) => void;
}

export const MenuContext = React.createContext<MenuContextValue>({
  menu: null,
  update: () => {},
});

export const MenuContextManager: React.FC = ({ children }) => {
  const [menu, _setMenu] = React.useState<Menu | null>(null);

  const update = (instance: Menu) => {
    _setMenu(instance);
  };

  return (
    <MenuContext.Provider
      value={{
        menu,
        update,
      }}
    >
      {children}
    </MenuContext.Provider>
  );
};
