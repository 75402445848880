import Button from "@material-ui/core/Button";
import { withStyles } from "@material-ui/core/styles";
import { colors } from "../../utils/colors";

export const TransparentButton = withStyles(() => ({
  root: {
    color: colors.authInputColor,
    padding: "8px 20px",
    height: 40,
    border: `1px solid ${colors.authInputBorder}`,
    textTransform: "none",
  },
}))(Button);

export const TransparentButtonMainColor = withStyles(() => ({
  root: {
    color: colors.mainButton,
    padding: "8px 20px",
    height: 40,
    border: `1px solid ${colors.mainButton}`,
    textTransform: "none",
  },
}))(Button);

export const TransparentButtonSmall = withStyles(() => ({
  root: {
    color: colors.authInputColor,
    padding: "0 5px",
    height: 30,
    border: `1px solid ${colors.authInputBorder}`,
    textTransform: "none",
  },
}))(Button);

export const TransparentButtonSmallDark = withStyles(() => ({
  root: {
    padding: "5px",
    height: 30,
    border: `1px solid ${colors.authInputBorder}`,
    textTransform: "none",
  },
}))(Button);

export const TransparentButtonDarkShadow = withStyles(() => ({
  root: {
    padding: "5px",
    height: 40,
    border: `1px solid ${colors.authInputBorder}`,
    textTransform: "none",
    boxShadow: `0px 3px 6px ${colors.authIconColor}`,
  },
}))(Button);

export const TransparentButtonDarkShadowLight = withStyles(() => ({
  root: {
    color: colors.authInputColor,
    padding: "8px 20px",
    height: 40,
    textTransform: "none",
    boxShadow: `0px 2px 4px ${colors.btnShadow}`,
  },
}))(Button);
