import React from "react";
import styled from "styled-components";
import { colors } from "modules/utils/colors";
import { LogoApp } from "modules/ui";
import { useHistory } from "react-router-dom";
import { lang } from "modules/utils/translations";

import { isMobile } from "react-device-detect";

interface AuthWrapperProps
  extends React.DetailedHTMLProps<
    React.HTMLAttributes<HTMLDivElement>,
    HTMLDivElement
  > {
  withoutTop?: boolean;
}

const AuthWrapper = styled.div<AuthWrapperProps>`
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: ${(props) => !props.withoutTop && "80px 0 20px 0"};
  color: ${colors.authSecondaryFontColor};
`;

const ComponentTitle = styled.h1`
  color: ${colors.authMainFontColor};
  margin: 45px 0 30px;
`;

const InformationText = styled.p`
  color: ${colors.authSecondaryFontColor};
  font-size: 30px;
  margin: 10px 0 50px;
  min-height: 40px;
  text-align: center;
`;

interface AuthFrameProps
  extends React.DetailedHTMLProps<
    React.HTMLAttributes<HTMLDivElement>,
    HTMLDivElement
  > {
  width?: string;
  topPadding?: number;
  topMargin?: number;
}

const AuthFrame = styled.div<AuthFrameProps>`
  width: ${(props) => (props.width ? props.width : "700px")};
  border-radius: 8px;
  position: relative;
  background-color: ${colors.authBackground};
  padding-top: ${(props) => (props.topPadding ? `${props.topPadding}px` : "")};
  margin-top: ${({ topMargin }) => (topMargin ? `${topMargin}px` : "")};
`;

const RegisterText = styled.span`
  font-weight: bold;
  text-decoration: underline;
  margin-left: 10px;
  cursor: pointer;
`;
const AuthFrameTriangle = styled.div`
  position: absolute;
  width: 0;
  height: 0;
  top: -20px;
  left: calc(50% - 20px);
  border-left: 20px solid transparent;
  border-right: 20px solid transparent;
  border-bottom: 30px solid ${colors.authBackground};
`;

interface AuthenticationWrapperProps {
  readonly children: React.ReactNode;
  readonly title?: string;
  readonly isRegister?: boolean;
  readonly customTextUnderTitle?: string;
  readonly withoutTop?: boolean;
  readonly topPadding?: number;
  readonly topMargin?: number;
  readonly width?: string;
}

export const AuthenticationWrapper: React.FC<AuthenticationWrapperProps> = ({
  children,
  title,
  isRegister,
  customTextUnderTitle,
  withoutTop,
  topPadding,
  topMargin,
  width,
}) => {
  const translation = lang.authentication.signIn;
  const history = useHistory();
  return (
    <AuthWrapper withoutTop={withoutTop}>
      {!withoutTop && (
        <>
          <LogoApp />
          <ComponentTitle>{title}</ComponentTitle>
          <InformationText>
            {isRegister && (
              <>
                {translation.haveAcc}
                <RegisterText onClick={() => history.push("/sign-up")}>
                  {translation.register}
                </RegisterText>
              </>
            )}
            {customTextUnderTitle && customTextUnderTitle}
          </InformationText>
        </>
      )}
      <AuthFrame
        width={isMobile ? "90%" : width}
        topPadding={topPadding}
        topMargin={topMargin}
      >
        <AuthFrameTriangle />
        {children}
      </AuthFrame>
    </AuthWrapper>
  );
};
