import { API } from "config";
import { APIRequest } from "./interfaces";
import type { Menu } from "modules/menu";
import { FailedResponse, SuccessResponse } from "modules/utils/response";
import { logger } from "./logger";

interface Params {
  menu: Menu;
}

const log = logger.extend("deleteMenuAsync");

/**
 * Api Service for deleting Menu instances.
 *
 * @param {Menu} menu - Menu instance.
 * @return {Promise<FailedResponse | SuccessResponse<null>>}
 */
export const deleteMenuAsync: APIRequest<Params> = async ({ menu }) => {
  const menuId = menu.getId();

  if (!menuId) {
    throw new Error("Missing id in Menu entity.");
  }

  log.info("Deleting menu `%s`.", menuId);

  const response = await fetch(API.menu.delete(menuId), {
    method: "DELETE",
    headers: {
      "Content-Type": "application/json",
      Accept: "application/json",
      Authorization: `JWT ${await localStorage.getItem("token")}`,
    },
  });

  log.debug("Response %O", response);

  if (response.status === 204) {
    log.info("Successfully deleted menu `%s`.", menuId);
    return new SuccessResponse(null);
  }

  log.warn("Error while deleting menu `%s`.", menuId);
  return new FailedResponse(await response.json());
};
