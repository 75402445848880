import { API } from "config";
import { APIRequest } from "./interfaces";
import { FailedResponse, SuccessResponse } from "modules/utils/response";
import { serialize } from "object-to-formdata";
import { logger } from "./logger";

interface Params {
  name: string;
  logo: File | string;
  facebook_url: string;
  instagram_url: string;
  twitter_url: string;
  business_number: string;
  city: string;
  address: string;
  postal_code: string;
  phone_number: string;
  opening_hours: string;
}

const log = logger.extend("complete data user");

/**
 * Api Service for register user
 *
 * @param registerToken - register token,
 * @param name - Company name.
 * @param logo - Company logo.
 * @param facebook_url - Facebook www address
 * @param instagram_url - Instagram www address
 * @param twitter_url - Twitter wwww address
 * @param business_number - Tax id
 * @param city - City name
 * @param address - Address of the company
 * @param postal_code - Postal cone
 * @param phone_number - Phone number
 * @param opening_hours - Opening hours of the restaurant
 *
 * @return {Promise<FailedResponse | SuccessResponse<null>>}
 */
export const completeDataUserAsync: APIRequest<Params> = async ({
  name,
  logo,
  facebook_url,
  instagram_url,
  twitter_url,
  business_number,
  city,
  address,
  postal_code,
  phone_number,
  opening_hours,
}) => {
  log.info("Complete data user `%s`.", name);
  const data = serialize({
    name,
    logo,
    facebook_url,
    instagram_url,
    twitter_url,
    business_number,
    city,
    address,
    postal_code,
    phone_number,
    opening_hours: JSON.stringify(opening_hours),
  });

  const response = await fetch(API.auth.completeData(), {
    method: "POST",
    headers: {
      Authorization: `JWT ${localStorage.getItem("token")}`,
    },
    body: data,
  });

  log.debug("Response %O", response);

  if (response.status === 200) {
    log.info("Successfully data completed `%s`.", name);
    return new SuccessResponse(null);
  }
  log.warn("Error while complete data `%s`.", name);
  return new FailedResponse(await response.json());
};
