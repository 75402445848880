import { ITypedMap, TypedMap } from "modules/utils/typedMap";
import { menuSelect } from "modules/utils/const";
import {
  deactivateAllOtherMenus,
  removeMenuAsync,
  saveMenuAsync,
  getSectionKey,
  getEntryKey,
  getSpecialEntryKey,
  getSpecialSectionKey,
  updateMenu,
} from "./services";
import _ from "lodash";
import I from "immutable";
import { logger } from "./logger";

const log = logger.extend("Menu");

export interface EntryDto {
  name: string;
  description: string;
  price: string;
  secondPrice: string;
  thirdPrice: string;
  note: string;
  spice: number;
  allergens: number[];
  priceType: number;
}
export interface SectionDto {
  name: string;
  description: string;
  entries: EntryDto[];
  firstPriceDescription: string;
  secondPriceDescription: string;
  thirdPriceDescription: string;
  firstColumnWidth: string;
  secondColumnWidth: string;
  thirdColumnWidth: string;
  price: string;
}

export interface SpecialSectionDto {
  name: string;
  entries: string[][];
  sectionFooter: string[];
}

export interface MenuTheme {
  header: number;
  description: number;
  allergenColor: number;
  price: number;
}

export interface SpecialMenu {
  mainPrice: string;
  isMenuSpecial: boolean;
}

export interface MenuDto {
  name: string;
  title: string;
  description: string;
  secondaryDescription?: string;
  style: string;
  sections: SectionDto[];
  active: boolean;
  menuCategory: number;
  footer: string;
  specialSections: SpecialSectionDto[];
  specialMenu: SpecialMenu;
  theme: MenuTheme;
}

export enum MenuType {
  Default,
  Special,
}

export interface APIMenuDto {
  id: string;
  type: MenuType;
  data: MenuDto;
}

export interface MenuData extends MenuDto {}

export enum MenuEvent {
  CHANGED = "menuChanged",
  CHANGED_REMOTE = "menuChangedRemote",
}

export enum MenuError {
  INVALID_DTO_OBJECT = "InvalidMenuDTOObjectError",
  INVALID_MENU_DATA_OBJECT = "InvalidMenuDataObjectError",
}

/** Class representing Menu. */
export class Menu {
  private _id: string | null;
  private _data: ITypedMap<MenuData>;
  private _type: MenuType;
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  private readonly _listeners: Record<string, ((payload?: any) => void)[]>;
  private _processing: boolean;

  /**
   * Construct menu object.
   * @param {MenuData} data - Data.
   * @param {string | null} id - Id of entity.
   * @param {string | null} type - Type of menu.
   */
  constructor(
    data: MenuData,
    id: string | null = null,
    type: MenuType = MenuType.Default
  ) {
    const safeData = this._getSafeData(data);
    this._id = id;
    this._type = type;
    this._data = TypedMap<MenuData>(safeData);
    this._listeners = {};
    this._processing = false;
  }

  private _getSafeEntries(entries: Partial<EntryDto[]>): EntryDto[] {
    return entries.map((entry) => ({
      name: _.get(entry, "name", ""),
      description: _.get(entry, "description", ""),
      price: _.get(entry, "price", "0"),
      secondPrice: _.get(entry, "secondPrice", "0"),
      thirdPrice: _.get(entry, "thirdPrice", "0"),
      note: _.get(entry, "note", ""),
      spice: _.get(entry, "spice", 0),
      allergens: _.get(entry, "allergens", []),
      priceType: _.get(entry, "priceType", 1),
    }));
  }

  private _getSafeSections(sections: Partial<SectionDto[]>): SectionDto[] {
    return sections.map((section) => ({
      name: _.get(section, "name", ""),
      description: _.get(section, "description", ""),
      entries: this._getSafeEntries(_.get(section, "entries", [])),
      firstPriceDescription: _.get(section, "firstPriceDescription", ""),
      secondPriceDescription: _.get(section, "secondPriceDescription", ""),
      thirdPriceDescription: _.get(section, "thirdPriceDescription", ""),
      firstColumnWidth: _.get(section, "firstColumnWidth", ""),
      secondColumnWidth: _.get(section, "secondColumnWidth", ""),
      thirdColumnWidth: _.get(section, "thirdColumnWidth", ""),
      price: _.get(section, "price", ""),
    }));
  }
  private _getSafeSpecialSections(
    sections: Partial<SpecialSectionDto>[]
  ): SpecialSectionDto[] {
    return sections.map((section) => ({
      name: _.get(section, "name", ""),
      sectionFooter: _.get(section, "sectionFooter", []),
      entries: _.get(section, "entries", []) as string[][],
    }));
  }

  private _getSafeData(data: Partial<MenuData>): MenuData {
    return {
      name: _.get(data, "name", ""),
      title: _.get(data, "title", ""),
      description: _.get(data, "description", ""),
      secondaryDescription: _.get(data, "secondaryDescription", ""),
      style: _.get(data, "style", ""),
      sections: this._getSafeSections(_.get(data, "sections", [])),
      active: _.get(data, "active", false),
      menuCategory: _.get(data, "menuCategory", 1),
      footer: _.get(data, "footer", ""),
      specialSections: this._getSafeSpecialSections(_.get(data, "specialSections", [])),
      theme: _.get(data, "theme", {
        header: 0,
        description: 0,
        price: 1,
        allergenColor: 0,
      }),
      specialMenu: _.get(data, "specialMenu", {
        mainPrice: "",
        isMenuSpecial: false,
      }),
    };
  }

  /**
   * Construct Menu from DTO object.
   * @param {Partial<APIMenuDto>} dto - Dto object received from API.
   * @return {Menu}
   */
  public static fromDto(dto: APIMenuDto): Menu {
    const { id, type, data } = dto;
    return new Menu(data, id, type);
  }

  /**
   * Construct DTO object.
   *
   * @return {MenuDto}
   */
  public toDto(): MenuDto {
    return this._data.toJS();
  }

  /**
   * Return raw MenuData.
   *
   * @return {MenuData}
   */
  public toRawData(): MenuData {
    return this._data.toJS();
  }

  /**
   * Return id.
   *
   * @return {string}
   */
  public getId(): string {
    return this._id || "";
  }

  /**
   * Return type.
   *
   * @return {MenuType}
   */
  public getEtag(): MenuType {
    return this._type;
  }

  /**
   * Is new flag. True if current Menu never was saved before and has
   *  no id.
   *
   * @return {boolean}
   */
  public isNew(): boolean {
    return !this._id;
  }

  /**
   * Return property from stored data.
   *
   * @param {keyof MenuData} key
   * @return {MenuData[T]}
   */
  public getProp<T extends keyof MenuData>(key: T): MenuData[T] {
    return this._data.get(key);
  }

  /**
   * Return computed hash from data.
   *
   * @return {number}
   */
  public get hash(): number {
    const data = I.fromJS(this._data.toJS());
    return data.hashCode();
  }

  /**
   * Compare this and other instance.
   *
   * @param {Menu} other - Another Menu instance.
   * @return {boolean}
   */
  public equals(other: Menu): boolean {
    return this._id === other._id && this.hash === other.hash;
  }

  /**
   * Return processing flag. True if currently doing call to API.
   *
   * @return {boolean}
   */
  public getProcessing(): boolean {
    return this._processing;
  }

  /**
   * Turn active flag to true and optionally update in API.
   *
   * @param {boolean} persist - Should update in API. Default: true.
   * @return {Promise<void>}
   */
  public async setActive(persist = true): Promise<void> {
    this._setData("active", true);

    if (persist) {
      this._processing = true;
      await deactivateAllOtherMenus(this);
      await updateMenu(this, true);
      this._emit(MenuEvent.CHANGED_REMOTE);
      this._processing = false;
    }
  }

  /**
   * Turn active flag to false and optionally update in API.
   *
   * @param {boolean} persist - Should update in API. Default: true.
   * @param {boolean} skipEmitEvent - Prevent emit CHANGE_REMOTE event.
   *  Default: false.
   * @return {Promise<void>}
   */
  public async setInactive(persist = true, skipEmitEvent = false): Promise<void> {
    this._setData("active", false);

    if (persist) {
      this._processing = true;
      await updateMenu(this, false);
      !skipEmitEvent && this._emit(MenuEvent.CHANGED_REMOTE);
      this._processing = false;
    }
  }

  /**
   * Toggle active flag.
   *
   * @return {Promise<void>}
   */
  public async toggleActive(): Promise<void> {
    if (this._data.get("active")) {
      await this.setInactive();
    } else {
      await this.setActive();
    }
  }

  /**
   * Update name of menu.
   *
   * @param {string} name - New name.
   * @return {Promise<void>}
   */
  public async updateName(name: string): Promise<void> {
    this._setData("name", name);
    this._processing = true;
    await updateMenu(this, this.getProp("active"));
    this._emit(MenuEvent.CHANGED_REMOTE);
    this._processing = false;
  }

  public async setTitle(title: string): Promise<void> {
    this._setData("title", title);
  }

  public async setName(name: string): Promise<void> {
    this._setData("name", name);
  }

  public async setType(type: number): Promise<void> {
    if (type === 0) {
      this._type = MenuType.Default;
    } else if (type === 1) {
      this._type = MenuType.Special;
    }
  }

  public async setDescription(description: string): Promise<void> {
    this._setData("description", description);
  }

  public async setMenuCategory(value: number): Promise<void> {
    this._setData("menuCategory", value);
  }

  public async setFooter(value: string): Promise<void> {
    this._setData("footer", value);
  }

  public async setSecondaryDescription(description: string): Promise<void> {
    this._setData("secondaryDescription", description);
  }

  public async addSection(after?: number, withEntry?: boolean): Promise<void> {
    const entries = [];
    if (withEntry) {
      entries.push({
        name: "",
        description: "",
        price: "",
        secondPrice: "",
        thirdPrice: "",
        note: "",
        spice: 0,
        allergens: [],
        priceType: 1,
      });
    }
    const newSection = {
      name: "",
      description: "",
      entries: entries,
      firstPriceDescription: "",
      secondPriceDescription: "",
      thirdPriceDescription: "",
      firstColumnWidth: "",
      secondColumnWidth: "",
      thirdColumnWidth: "",
      price: "",
    };
    const sections = [...this.getProp("sections")];
    if (after === undefined) {
      sections.push(newSection);
    } else {
      sections.splice(after + 1, 0, newSection);
    }
    this._setData("sections", sections);
  }

  public async addSpecialSection(after?: number): Promise<void> {
    const newSection = {
      name: "",
      sectionFooter: [""],
      entries: [],
    };
    const specialSections = [...this.getProp("specialSections")];
    if (after === undefined) {
      specialSections.push(newSection);
    } else {
      specialSections.splice(after + 1, 0, newSection);
    }
    this._setData("specialSections", specialSections);
  }

  public async duplicateSection(sectionIndex: number): Promise<void> {
    const sections = JSON.parse(JSON.stringify(this.getProp("sections")));
    const newSection = JSON.parse(JSON.stringify(sections[sectionIndex]));
    sections.splice(sectionIndex + 1, 0, newSection);
    this._setData("sections", sections);
  }

  public async duplicateSpecialSection(sectionIndex: number): Promise<void> {
    const specialSections = JSON.parse(JSON.stringify(this.getProp("specialSections")));
    const newSection = JSON.parse(JSON.stringify(specialSections[sectionIndex]));
    specialSections.splice(sectionIndex + 1, 0, newSection);
    this._setData("specialSections", specialSections);
  }

  public async deleteSection(sectionIndex: number): Promise<void> {
    const sections = [...this.getProp("sections")];
    sections.splice(sectionIndex, 1);
    this._setData("sections", sections);
  }

  public async deleteSpecialSection(sectionIndex: number): Promise<void> {
    const sections = [...this.getProp("specialSections")];
    sections.splice(sectionIndex, 1);
    this._setData("specialSections", sections);
  }

  public async setSectionName(
    sectionIndex: number,
    sectionName: string
  ): Promise<void> {
    const sections = JSON.parse(JSON.stringify(this.getProp("sections")));
    sections[sectionIndex].name = sectionName;
    this._setData("sections", sections);
  }

  public async setSectionFooterText(
    sectionIndex: number,
    footerTextIndex: number,
    footerText: string
  ): Promise<void> {
    const sections = JSON.parse(JSON.stringify(this.getProp("specialSections")));
    sections[sectionIndex].sectionFooter[footerTextIndex] = footerText;
    this._setData("specialSections", sections);
  }

  public async addSectionFooterTextLine(sectionIndex: number): Promise<void> {
    const sections = JSON.parse(JSON.stringify(this.getProp("specialSections")));
    sections[sectionIndex].sectionFooter.push("");
    this._setData("specialSections", sections);
  }

  public async deleteSpecialSectionFooterLine(sectionIndex: number): Promise<void> {
    const sections = [...this.getProp("specialSections")];
    sections[sectionIndex].sectionFooter.splice(-1, 1);
    this._setData("specialSections", sections);
  }

  public async setSpecialSectionName(
    sectionIndex: number,
    sectionName: string
  ): Promise<void> {
    const sections = JSON.parse(JSON.stringify(this.getProp("specialSections")));
    sections[sectionIndex].name = sectionName;
    this._setData("specialSections", sections);
  }

  public async setSectionDescription(
    sectionIndex: number,
    sectionDescription: string
  ): Promise<void> {
    const sections = JSON.parse(JSON.stringify(this.getProp("sections")));
    sections[sectionIndex].description = sectionDescription;
    this._setData("sections", sections);
  }

  public async setSectionFirstPriceDescription(
    sectionIndex: number,
    value: string
  ): Promise<void> {
    const sections = JSON.parse(JSON.stringify(this.getProp("sections")));
    sections[sectionIndex].firstPriceDescription = value;
    this._setData("sections", sections);
  }

  public async setSectionSecondPriceDescription(
    sectionIndex: number,
    value: string
  ): Promise<void> {
    const sections = JSON.parse(JSON.stringify(this.getProp("sections")));
    sections[sectionIndex].secondPriceDescription = value;
    this._setData("sections", sections);
  }

  public async setSectionThirdPriceDescription(
    sectionIndex: number,
    value: string
  ): Promise<void> {
    const sections = JSON.parse(JSON.stringify(this.getProp("sections")));
    sections[sectionIndex].thirdPriceDescription = value;
    this._setData("sections", sections);
  }

  public async setSectionFirstColumnWidth(
    sectionIndex: number,
    value: string
  ): Promise<void> {
    const sections = JSON.parse(JSON.stringify(this.getProp("sections")));
    sections[sectionIndex].firstColumnWidth = value;
    this._setData("sections", sections);
  }

  public getSectionFirstColumnWidth(sectionIndex: number): string {
    const sections = JSON.parse(JSON.stringify(this.getProp("sections")));
    return sections[sectionIndex].firstColumnWidth;
  }

  public getSectionSecondColumnWidth(sectionIndex: number): string {
    const sections = JSON.parse(JSON.stringify(this.getProp("sections")));
    return sections[sectionIndex].secondColumnWidth;
  }

  public getSectionThirdColumnWidth(sectionIndex: number): string {
    const sections = JSON.parse(JSON.stringify(this.getProp("sections")));
    return sections[sectionIndex].thirdColumnWidth;
  }

  public async setSectionSecondColumnWidth(
    sectionIndex: number,
    value: string
  ): Promise<void> {
    const sections = JSON.parse(JSON.stringify(this.getProp("sections")));
    sections[sectionIndex].secondColumnWidth = value;
    this._setData("sections", sections);
  }

  public async setSectionThirdColumnWidth(
    sectionIndex: number,
    value: string
  ): Promise<void> {
    const sections = JSON.parse(JSON.stringify(this.getProp("sections")));
    sections[sectionIndex].thirdColumnWidth = value;
    this._setData("sections", sections);
  }

  public async addEntry(sectionIndex: number, after?: number): Promise<void> {
    const newEntry = {
      name: "",
      description: "",
      price: "",
      secondPrice: "",
      thirdPrice: "",
      note: "",
      spice: 0,
      allergens: [],
      priceType: 1,
    };
    const sections = JSON.parse(JSON.stringify(this.getProp("sections")));

    if (after === undefined) {
      sections[sectionIndex].entries.push(newEntry);
    } else {
      sections[sectionIndex].entries.splice(after + 1, 0, newEntry);
    }
    this._setData("sections", sections);
  }

  public async addSpecialEntry(sectionIndex: number, after?: number): Promise<void> {
    const sections = JSON.parse(JSON.stringify(this.getProp("specialSections")));

    if (after === undefined) {
      sections[sectionIndex].entries.push([""]);
    } else {
      sections[sectionIndex].entries.splice(after + 1, 0, []);
    }
    this._setData("specialSections", sections);
  }

  public async addSpecialEntryLine(
    sectionIndex: number,
    entryIndex: number
  ): Promise<void> {
    const sections = JSON.parse(JSON.stringify(this.getProp("specialSections")));
    sections[sectionIndex].entries[entryIndex].push("");
    this._setData("specialSections", sections);
  }

  public async deleteSpecialEntryLine(
    sectionIndex: number,
    entryIndex: number
  ): Promise<void> {
    const sections = JSON.parse(JSON.stringify(this.getProp("specialSections")));
    sections[sectionIndex].entries[entryIndex].splice(-1, 1);
    this._setData("specialSections", sections);
  }

  public async duplicateEntry(sectionIndex: number, entryIndex: number): Promise<void> {
    const sections = this.getProp("sections");
    const newEntry = JSON.parse(
      JSON.stringify(sections[sectionIndex].entries[entryIndex])
    );
    sections[sectionIndex].entries.splice(entryIndex + 1, 0, newEntry);
    this._setData("sections", sections);
  }

  public async duplicateSpecialEntry(
    sectionIndex: number,
    entryIndex: number
  ): Promise<void> {
    const sections = JSON.parse(JSON.stringify(this.getProp("specialSections")));
    const newEntry = JSON.parse(
      JSON.stringify(sections[sectionIndex].entries[entryIndex])
    );
    sections[sectionIndex].entries.splice(entryIndex + 1, 0, newEntry);
    this._setData("specialSections", sections);
  }

  public async deleteEntry(sectionIndex: number, entryIndex: number): Promise<void> {
    const sections = [...this.getProp("sections")];
    sections[sectionIndex].entries.splice(entryIndex, 1);
    this._setData("sections", sections);
  }

  public async deleteSpecialEntry(
    sectionIndex: number,
    entryIndex: number
  ): Promise<void> {
    const sections = [...this.getProp("specialSections")];
    sections[sectionIndex].entries.splice(entryIndex, 1);
    this._setData("specialSections", sections);
  }

  public async setSpecialEntryValue(
    sectionIndex: number,
    entryIndex: number,
    valueIndex: number,
    value: string
  ): Promise<void> {
    const sections = JSON.parse(JSON.stringify(this.getProp("specialSections")));
    sections[sectionIndex].entries[entryIndex][valueIndex] = value;
    this._setData("specialSections", sections);
  }

  public async setEntryName(
    sectionIndex: number,
    entryIndex: number,
    entryName: string
  ): Promise<void> {
    const sections = JSON.parse(JSON.stringify(this.getProp("sections")));
    sections[sectionIndex].entries[entryIndex].name = entryName;
    this._setData("sections", sections);
  }

  public async setEntryDescription(
    sectionIndex: number,
    entryIndex: number,
    entryDescription: string
  ): Promise<void> {
    const sections = JSON.parse(JSON.stringify(this.getProp("sections")));
    sections[sectionIndex].entries[entryIndex].description = entryDescription;
    this._setData("sections", sections);
  }

  public async setEntryPrice(
    sectionIndex: number,
    entryIndex: number,
    entryPrice: string
  ): Promise<void> {
    const sections = JSON.parse(JSON.stringify(this.getProp("sections")));
    sections[sectionIndex].entries[entryIndex].price = entryPrice;
    this._setData("sections", sections);
    this.changePrices();
  }

  public async setMainPrice(price: string): Promise<void> {
    const specialMenu = JSON.parse(JSON.stringify(this.getProp("specialMenu")));
    specialMenu.mainPrice = price;
    this._setData("specialMenu", specialMenu);
    this.changePrices();
  }

  public async setSectionPrice(sectionIndex: number, price: string): Promise<void> {
    const sections = JSON.parse(JSON.stringify(this.getProp("sections")));
    sections[sectionIndex].price = price;
    this._setData("sections", sections);
    this.changePrices();
  }

  public async setIsSpecialMenu(isMenuSpecial: boolean): Promise<void> {
    const specialMenu = JSON.parse(JSON.stringify(this.getProp("specialMenu")));
    specialMenu.isMenuSpecial = isMenuSpecial;
    this._setDataNoEmit("specialMenu", specialMenu);
  }

  public async setTemplate(templateName: string): Promise<void> {
    this._setData("style", templateName);
  }

  // THEMES

  public async changePrices(): Promise<void> {
    const specialMenu = JSON.parse(JSON.stringify(this.getProp("specialMenu")));
    const sections = JSON.parse(JSON.stringify(this.getProp("sections")));
    const priceType = this.getProp("theme").price;
    const from = priceType === 1 ? "." : ",";
    const to = priceType === 1 ? "," : ".";
    specialMenu.mainPrice = specialMenu.mainPrice.replace(from, to);
    sections.map((section: SectionDto) => {
      section.price = section.price.replace(from, to);
      return section.entries.map((entry: EntryDto) => {
        const newEntry = Object.assign({}, entry);
        entry.price = entry.price.replace(from, to);
        if (entry.secondPrice) {
          entry.secondPrice = entry.secondPrice.replace(from, to);
        }
        if (entry.thirdPrice) {
          entry.thirdPrice = entry.thirdPrice.replace(from, to);
        }
        return newEntry;
      });
    });
    this._setData("specialMenu", specialMenu);
    this._setData("sections", sections);
  }

  public async setEntryPriceSecond(
    sectionIndex: number,
    entryIndex: number,
    entryPrice: string
  ): Promise<void> {
    const sections = JSON.parse(JSON.stringify(this.getProp("sections")));
    const themes = this.getProp("theme");
    const from = themes.price === 1 ? "." : ",";
    const to = themes.price === 1 ? "," : ".";
    sections[sectionIndex].entries[entryIndex].secondPrice = entryPrice.replace(
      from,
      to
    );
    this._setData("sections", sections);
  }

  public async setEntryPriceThird(
    sectionIndex: number,
    entryIndex: number,
    entryPrice: string
  ): Promise<void> {
    const sections = JSON.parse(JSON.stringify(this.getProp("sections")));
    const themes = this.getProp("theme");
    const from = themes.price === 1 ? "." : ",";
    const to = themes.price === 1 ? "," : ".";
    sections[sectionIndex].entries[entryIndex].thirdPrice = entryPrice.replace(
      from,
      to
    );
    this._setData("sections", sections);
  }

  public getTheme(): MenuTheme {
    const theme = JSON.parse(JSON.stringify(this.getProp("theme")));
    return theme;
  }

  public setTheme(theme: MenuTheme): void {
    const _theme = Object.assign({}, this.getProp("theme"), theme);
    this._setData("theme", _theme);
  }

  public async setThemeHeader(header: number): Promise<void> {
    const theme = Object.assign({}, this.getProp("theme"));
    theme.header = header;

    this._setData("theme", theme);
  }

  public async setThemeDescription(description: number): Promise<void> {
    const theme = Object.assign({}, this.getProp("theme"));
    theme.description = description;

    this._setData("theme", theme);
  }

  public async setThemeAllergens(allergenColor: number): Promise<void> {
    const theme = Object.assign({}, this.getProp("theme"));
    theme.allergenColor = allergenColor;

    this._setData("theme", theme);
  }

  public async setThemePrice(price: number): Promise<void> {
    const theme = Object.assign({}, this.getProp("theme"));
    theme.price = price;

    this._setData("theme", theme);
  }

  public async setEntryPriceType(
    sectionIndex: number,
    entryIndex: number,
    priceType: number
  ): Promise<void> {
    const sections = JSON.parse(JSON.stringify(this.getProp("sections")));
    sections[sectionIndex].entries[entryIndex].priceType = priceType;
    this._setData("sections", sections);
  }

  public async setEntryNote(
    sectionIndex: number,
    entryIndex: number,
    note: string
  ): Promise<void> {
    const sections = JSON.parse(JSON.stringify(this.getProp("sections")));
    sections[sectionIndex].entries[entryIndex].note = note;
    this._setData("sections", sections);
  }

  public async setEntryAllergens(
    sectionIndex: number,
    entryIndex: number,
    allergens: number[]
  ): Promise<void> {
    const sections = JSON.parse(JSON.stringify(this.getProp("sections")));
    sections[sectionIndex].entries[entryIndex].allergens = allergens;
    this._setData("sections", sections);
  }

  public async setEntrySpice(
    sectionIndex: number,
    entryIndex: number,
    spice: number
  ): Promise<void> {
    const sections = JSON.parse(JSON.stringify(this.getProp("sections")));
    sections[sectionIndex].entries[entryIndex].spice = spice;
    this._setData("sections", sections);
  }

  /**
   * Save this Menu. It will POST new menu if it has no `id` or PUT
   *  if it's Menu retrieved from API.
   * @return {Promise<void>}
   */
  public async save(): Promise<void> {
    await saveMenuAsync(this);
    this._emit(MenuEvent.CHANGED_REMOTE);
    this._processing = false;
  }

  /**
   * Delete this Menu.
   *
   * @return {Promise<void>}
   */
  public async remove(): Promise<void> {
    this._processing = true;
    await removeMenuAsync(this);
    this._emit(MenuEvent.CHANGED_REMOTE);
    this._processing = false;
  }

  public updateWithData(data: Partial<MenuData>): void {
    const rawData = this._data.toJS();
    const oldState = JSON.parse(JSON.stringify(rawData));
    const theme = this.getProp("theme");
    const emptyData = _.omit(data, ["style"]);
    const _data = Object.assign(
      {},
      {
        ...rawData,
        ...emptyData,
        theme,
      }
    );
    this._data = TypedMap<MenuData>(_data);
    const newState = this._data.toJS();
    this._emit(MenuEvent.CHANGED, {
      before: oldState,
      after: newState,
    });
  }

  private _setData<T extends keyof MenuData>(property: T, value: MenuData[T]): void {
    const oldState = JSON.parse(JSON.stringify(this._data.toJS()));
    this._data = this._data.set(property, value) as ITypedMap<MenuData>;
    const newState = this._data.toJS();
    this._emit(MenuEvent.CHANGED, {
      before: oldState,
      after: newState,
    });
  }

  private _setDataNoEmit<T extends keyof MenuData>(
    property: T,
    value: MenuData[T]
  ): void {
    this._data = this._data.set(property, value) as ITypedMap<MenuData>;
  }

  /**
   * Register event listener. Return object with unsubscribe method for
   *  given listener.
   *
   * @param {MenuEvent} event - Event type.
   * @param {() => void} callback - Callback.
   * @return {{unsubscribe: () => void}}
   */
  public on(
    event: MenuEvent,
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    callback: (payload?: any) => void
  ): { unsubscribe: () => void } {
    if (!(event in this._listeners)) {
      this._listeners[event] = [];
    }
    this._listeners[event].push(callback);

    return {
      unsubscribe: () => this.unsubscribe(event, callback),
    };
  }

  /**
   * Unsubscribe event listener.
   *
   * @param {MenuEvent} event - Event type.
   * @param {() => void} callback - Callback. Must be the same
   *  (reference) function as used as callback while registering.
   */
  public unsubscribe(event: MenuEvent, callback: () => void): void {
    if (event in this._listeners) {
      this._listeners[event] = this._listeners[event].filter((cb) => cb !== callback);
    }
  }

  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  private _emit(event: MenuEvent, payload?: any) {
    log.debug("emit: %s", event);
    if (event in this._listeners) {
      this._listeners[event].forEach((cb) => cb(payload));
    }
  }

  public static createNew(menuCategory: number, specialMenu?: boolean): Menu {
    const menu = new Menu(menuSelect(menuCategory, specialMenu));
    menu.setMenuCategory(menuCategory);
    return menu;
  }

  public validate(specialSections: boolean): Record<string, string> | null {
    const errors: Record<string, string> = {};
    if (!this.getProp("title")) {
      errors.name = "Missing title";
    }
    const specialMenu = this.getProp("specialMenu");
    const sections = this.getProp("sections");

    if (specialMenu.isMenuSpecial) {
      if (!this.getProp("description")) {
        errors.description = "Missing description";
      }
      if (!specialMenu.mainPrice) {
        errors.mainPrice = "Missing main price";
      }
    }
    sections.forEach((section, index) => {
      const sectionKey = getSectionKey(index);
      if (!section.name) {
        errors[sectionKey + "-name"] = "Missing section name";
      }

      section.entries.forEach((entry, index) => {
        const entryKey = getEntryKey(sectionKey, index);
        if (!entry.name && !specialMenu.isMenuSpecial) {
          errors[entryKey + "-name"] = "Missing entry name";
        }

        if (!entry.price && !specialMenu.isMenuSpecial) {
          errors[entryKey + "-price"] = "Missing entry price";
        }
      });
    });

    if (specialSections) {
      const specialSections = this.getProp("specialSections");
      specialSections.forEach((section, index) => {
        const sectionKey = getSpecialSectionKey(index);
        if (!section.name) {
          errors[sectionKey + "-name"] = "Missing section name";
        }
        section.sectionFooter.forEach((entry, index) => {
          if (!entry) {
            errors[sectionKey + `${index}-specialFooter`] =
              "Missing special section entry footer";
          }
        });
        section.entries.forEach((entry, index) => {
          const entryKey = getSpecialEntryKey(sectionKey, index);
          entry.forEach((entryText, indexText) => {
            if (!entryText) {
              errors[entryKey + `${indexText}-description`] =
                "Missing special section entry description";
            }
          });
        });
      });
    }

    if (Object.keys(errors).length > 0) {
      return errors;
    }

    return null;
  }
}
