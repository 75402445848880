import { ResponseError } from "./interface";
import { ITypedMap, TypedMap } from "../typedMap";

/** Class representing success Response. */
// eslint-disable-next-line @typescript-eslint/no-explicit-any
export class SuccessResponse<T = null> {
  public readonly success = true;
  /**
   * Data attached.
   * @type ITypedMap<T>
   * @private
   */
  private readonly _data: T;

  /**
   * Create success type of Response.
   * @param {T} data - Data attached.
   */
  constructor(data: T) {
    this._data = data;
  }

  /**
   * Return stored data.
   * @return {T}
   */
  public getData(): T {
    return this._data;
  }
}

/** Class representing failed Response. */
export class FailedResponse {
  public readonly success = false;
  /**
   * Error attached.
   * @type {ITypedMap<ResponseError>}
   * @private
   */
  private readonly _error: ITypedMap<ResponseError>;

  /**
   * Create success type of Response.
   * @param {E} error - Data attached.
   */
  constructor(error: ResponseError) {
    this._error = TypedMap<ResponseError>(error);
  }

  /**
   * Return stored data.
   * @return {E}
   */
  public getError(): ResponseError {
    return this._error.toJS();
  }
}
