import React from "react";
import GoogleFontLoader from "react-google-font-loader";

export const GoogleFonts: React.FC = () => (
  <GoogleFontLoader
    fonts={[
      {
        font: "Roboto",
        weights: [400, "400i", "bold"],
      },
      {
        font: "Averia Serif Libre",
        weights: [400, "400i", "bold"],
      },
      {
        font: "Shrikhand",
        weights: [400, "400i", "bold"],
      },
      {
        font: "Montserrat",
        weights: [400, "400i", "bold"],
      },
      {
        font: "Rockwell",
        weights: [400, "400i", "bold"],
      },
      {
        font: "Playfair",
        weights: [400, "400i", "bold"],
      },
      {
        font: "Fascinate",
        weights: [400, "400i", "bold"],
      },
      {
        font: "Plaster",
        weights: [400, "400i", "bold"],
      },
      {
        font: "Permanent Marker",
        weights: [400, "400i", "bold"],
      },
      {
        font: "Barrio",
        weights: [400, "400i", "bold"],
      },
      {
        font: "Fascinate Inline",
        weights: [400, "400i", "bold"],
      },
      {
        font: "Bellota",
        weights: [300, 400, "bold"],
      },
      {
        font: "Open Sans",
        weights: [300, 400, "bold"],
      },
      {
        font: "Playball",
        weights: [400],
      },
      {
        font: "Josefin Sans",
        weights: [300],
      },
      {
        font: "Noto Sans",
        weights: [400, 700],
      },
    ]}
  />
);
