import styled from "styled-components";
import { colors } from "modules/utils/colors";

export const Logo = styled.div`
  text-transform: uppercase;
  display: flex;
  justify-content: center;
  align-items: center;
  font-size: 12px;
  color: ${colors.mainFont};
`;
