import React from "react";
import { lang } from "modules/utils/translations";
import { Helmet } from "react-helmet";

interface SEOProps {
  readonly title: string;
}

export const SEO: React.FC<SEOProps> = ({ title }) => {
  const language = lang.getLanguage();
  return (
    <Helmet>
      <html lang={language} className="notranslate" translate="no" />
      <meta charSet="utf-8" />
      <meta name="google" content="notranslate" />
      <title>{title}</title>
    </Helmet>
  );
};

export default SEO;
