import React, {
  forwardRef,
  useEffect,
  useImperativeHandle,
  useRef,
  useState,
} from "react";
import styled from "styled-components";
import BorderColorIcon from "@material-ui/icons/BorderColor";
import useOnClickOutside from "use-onclickoutside";
import { colors } from "../../utils/colors";

export const IconEditName = styled(BorderColorIcon)`
  margin-left: 8px;
  cursor: pointer;
  position: relative;
  top: 2px;
  display: flex;
  font-size: 16px !important;
  height: 10px;
`;

const Input = styled.input`
  border: 0;
  outline: 0;
  min-width: 80px;
  background: transparent;
  font-family: inherit;
  font-size: inherit;
  font-weight: inherit;
  text-align: inherit;
  margin: 0;
  max-width: 100%;
  color: inherit;
  position: relative;
  top: 0;
  left: -2px;

  &::placeholder {
    color: ${colors.placeholder};
  }
`;

const HiddenSpan = styled.span`
  font: inherit;
  margin: 0;
  padding: 0;
  white-space: pre;
  position: absolute;
  visibility: hidden;
  z-index: -1000;
`;

const Wrapper = styled.div`
  width: auto;
  overflow: hidden;
  max-width: 100%;
`;

const PRICE_FORMAT = /^\d+([,.]\d{0,2})?$/;

interface EditDataRefObj {
  turnOnEditMode: () => void;
  turnOffEditMode: () => void;
}
export type EditDataRef = EditDataRefObj | undefined;

interface EditDataInputProps {
  readonly ref?: React.Ref<EditDataRef>;
  readonly inputKey: string;
  readonly placeholder?: string;
  readonly initialValue: string;
  readonly checkingSize?: boolean;
  readonly type?: string;
  readonly className?: string;
  readonly autoFocus?: boolean;
  readonly onSave: (value: string) => void;
  readonly onBlur?: () => void;
  readonly maxWidth?: string;
  readonly allowEmpty?: boolean;
  readonly icon?: boolean;
  readonly maxHeight?: string;
  readonly maxLength?: number;
}

export const EditDataInput: React.ForwardRefExoticComponent<EditDataInputProps> = forwardRef<
  EditDataRef,
  EditDataInputProps
>(
  (
    {
      inputKey,
      placeholder = "",
      initialValue,
      type,
      className = "",
      maxHeight,
      autoFocus,
      onSave,
      maxWidth = "auto",
      allowEmpty = false,
      icon,
      maxLength,
      onBlur,
      checkingSize = true,
    },
    ref
  ) => {
    const wrapperRef = useRef<HTMLDivElement>(null);
    const inputRef = useRef<HTMLInputElement>(null);
    const spanRef = useRef<HTMLSpanElement>(null);
    const [editMode, setEditMode] = useState<boolean>(false);
    const [value, setValue] = useState<string>(initialValue);
    const [width, setWidth] = useState<string | number>(1);

    const getInputSize = () => {
      const size = (spanRef.current && spanRef.current.offsetWidth) || 20;
      return size;
    };

    const updateInputWidth = () => {
      const width = checkingSize ? getInputSize() : "100%";
      setWidth(width);
    };

    useImperativeHandle(ref, () => ({
      turnOnEditMode: () => {
        setEditMode(true);
        inputRef.current && inputRef.current.focus();
      },
      turnOffEditMode: () => setEditMode(false),
    }));

    useEffect(() => {
      if (editMode && inputRef.current) {
        inputRef.current && inputRef.current.focus();
      } else if (!editMode && onBlur) {
        onBlur();
      }
    }, [inputKey, editMode]);

    useEffect(() => {
      setValue(initialValue);
    }, [initialValue]);

    useEffect(() => {
      updateInputWidth();
    }, [value]);

    const save = () => {
      setEditMode(false);
      if ((!!allowEmpty || value.length > 0) && value !== initialValue) {
        onSave(value);
      } else {
        setValue(initialValue);
      }
    };

    const _handleEnterPress = (event: React.KeyboardEvent<HTMLDivElement>) => {
      if (event.key === "Enter") {
        save();
      }
    };

    useOnClickOutside(wrapperRef, (e) => {
      e.stopPropagation();
      save();
    });

    const onChangeText = (text: string) => {
      if (type === "price") {
        if (!PRICE_FORMAT.test(text) && text.length) {
          return;
        }
        if (text.length === 0) {
          setValue(text);
          return;
        }
      }

      setValue(text);
    };

    const style = {
      width,
      maxWidth,
      maxHeight,
    };

    return (
      <Wrapper ref={wrapperRef} onClick={() => setEditMode(true)}>
        <HiddenSpan ref={spanRef} style={{ maxWidth }} className={className}>
          {value || initialValue || placeholder}
        </HiddenSpan>
        {editMode ? (
          <Input
            id={inputKey}
            ref={inputRef}
            className={className}
            placeholder={placeholder}
            value={value}
            type={"text"}
            onChange={(e) => onChangeText(e.target.value)}
            onKeyDown={_handleEnterPress}
            autoFocus={autoFocus}
            maxLength={maxLength}
            style={style}
          />
        ) : value ? (
          <span className={className} id={inputKey}>
            {value}
          </span>
        ) : (
          <span
            className={className}
            id={inputKey}
            style={{ color: colors.placeholder }}
          >
            {placeholder}
          </span>
        )}
        {icon && !editMode && <IconEditName />}
      </Wrapper>
    );
  }
);
