import { API } from "config";
import { UserProfile } from "./interfaces";
import { logger } from "./logger";

const log = logger.extend("getUserProfile");

/**
 * Api Service to get user profile
 * @return {Promise<UserProfile>}
 */

export const getUserProfile = async (): Promise<UserProfile> => {
  log.info("Getting user profile");

  const response = await fetch(API.auth.getUserData(), {
    method: "GET",
    headers: {
      "Content-Type": "application/json",
      Accept: "application/json",
      Authorization: `JWT ${await localStorage.getItem("token")}`,
    },
  });
  log.debug("Response %O", response);

  if (response.status === 200) {
    log.info("Successfully got user profile.");
    const userData = await response.json();
    return userData;
  }

  log.info("Error while fetching user profile");
  return await response.json();
};
