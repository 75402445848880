// const API_HOST = "https://mindful-dev.milosolutions.com";

// Every time I build the app using the env var, it is added twice on the requests, but not if I call console.log API.auth.xxxx () (???)
/*
const API_HOST = `${
  process.env.NODE_ENV === "production" ? `${process.env.REACT_APP_API_BACKEND}` : ""
}`;
*/

const API_HOST = `${
  process.env.NODE_ENV === "production" ? "https://app.gastronomiaqr.com" : ""
}`;

const V = "/api/v1";

export const API = Object.freeze({
  auth: Object.freeze({
    authenticate: () => `${API_HOST}${V}/users/authenticate/`,
    resetPassword: () => `${API_HOST}${V}/users/password/reset/request/`,
    registerUser: () => `${API_HOST}${V}/users/signup/`,
    completeData: () => `${API_HOST}${V}/users/profile/create/`,
    getUserData: () => `${API_HOST}${V}/users/profile/retrieve/`,
    updateProfile: () => `${API_HOST}${V}/users/profile/update/`,
    signatureData: () => `${API_HOST}${V}/users/payment/agreement/create/`,
    changePassword: () => `${API_HOST}${V}/users/password/reset/setnew/`,
    skipPayment: () => `${API_HOST}${V}/users/payment/agreement/skip/`,
  }),
  menu: Object.freeze({
    list: () => `${API_HOST}${V}/menu/list/`,
    delete: (id: string) => `${API_HOST}${V}/menu/destroy/${id}/`,
    create: () => `${API_HOST}${V}/menu/create/`,
    entity: (id: string) => `${API_HOST}${V}/menu/retrieve/pk/${id}/`,
    update: (id: string) => `${API_HOST}${V}/menu/update/${id}/`,
    active: () => `${API_HOST}${V}/menu/retrieve/`,
    getActiveMenu: (slug: string) => `${API_HOST}${V}/menu/retrieve/slug/${slug}/`,
  }),
  files: Object.freeze({
    qrCodes: () => `${API_HOST}${V}/users/file/qr-codes/`,
    paymentAgreement: () => `${API_HOST}${V}/users/file/payment-agreement/`,
  }),
});
