import { API } from "config";
import { APIRequest } from "./interfaces";
import { Menu } from "modules/menu";
import { FailedResponse, SuccessResponse } from "modules/utils/response";
import { logger } from "./logger";

interface Params {
  menu: Menu;
}

interface Data {
  id: string;
}

const log = logger.extend("postMenuAsync");

/**
 * Api Service for posting new Menu.
 *
 * @param {Menu} menu - Menu instance (without id).
 * @return {Promise<FailedResponse | SuccessResponse<null>>}
 */
export const postMenuAsync: APIRequest<Params, Data> = async ({ menu }) => {
  if (!menu.isNew()) {
    throw new Error("Cannot add existing entity.");
  }
  log.info("Posting new menu.");
  const data = menu.toDto();
  log.debug("Data to post %O.", data);

  const response = await fetch(API.menu.create(), {
    method: "POST",
    headers: {
      "Content-Type": "application/json",
      Accept: "application/json",
      Authorization: `JWT ${await localStorage.getItem("token")}`,
    },
    body: JSON.stringify({ data: data, category: data.menuCategory }),
  });

  log.debug("Response %O", response);
  if (response.status === 200) {
    const rawData = await response.json();
    const id = rawData._id;

    log.info("Successfully posted menu.");
    return new SuccessResponse({ id });
  }

  log.warn("Error while posting menu");
  return new FailedResponse(await response.json());
};
