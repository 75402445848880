import React from "react";
import styled from "styled-components";
import CircularProgress from "@material-ui/core/CircularProgress";

const LoaderBackdrop = styled.div`
  position: fixed;
  width: 100vw;
  height: 100vh;
  top: 0;
  left: 0;
  background-color: rgba(0, 0, 0, 0.5);
  z-index: 1000;
`;

const LoaderWrapper = styled.div`
  position: absolute;
  top: 50%;
  left: 50%;
`;

interface LoaderProps {
  readonly loading?: boolean;
}

export const Loader: React.FC<LoaderProps> = ({ loading = true }) => {
  return (
    <>
      {loading ? (
        <LoaderBackdrop>
          <LoaderWrapper>
            <CircularProgress color="primary" />
          </LoaderWrapper>
        </LoaderBackdrop>
      ) : null}
    </>
  );
};

const SmallLoaderWrapper = styled.div`
  margin-right: 9px;
  margin-left: -25px;
  display: flex;
`;

export const SmallLoader: React.FC = () => (
  <SmallLoaderWrapper>
    <CircularProgress color="primary" size={20} />
  </SmallLoaderWrapper>
);
