import gluten, { ReactComponent as Gluten } from "modules/ui/assets/gluten-icon.svg";
import eggs, { ReactComponent as Eggs } from "modules/ui/assets/eggs-icon.svg";
import lactose, { ReactComponent as Lactose } from "modules/ui/assets/lactose-icon.svg";
import fish, { ReactComponent as Fish } from "modules/ui/assets/fish-icon.svg";
import sea, { ReactComponent as Sea } from "modules/ui/assets/sea-icon.svg";
import shelfish, {
  ReactComponent as Shelfish,
} from "modules/ui/assets/shelfish-icon.svg";
import nuts, { ReactComponent as Nuts } from "modules/ui/assets/nut-icon.svg";
import soy, { ReactComponent as Soy } from "modules/ui/assets/soy-icon.svg";
import celer, { ReactComponent as Celer } from "modules/ui/assets/celer-icon.svg";
import mustard, { ReactComponent as Mustart } from "modules/ui/assets/mustard-icon.svg";
import sulfur, { ReactComponent as Sulfur } from "modules/ui/assets/sulfur-icon.svg";
import seasame, { ReactComponent as Seasame } from "modules/ui/assets/seasame-icon.svg";

import { lang } from "modules/utils/translations";

const translatedName = lang.allergens.names;
const translatedDescription = lang.allergens.descriptions;

export interface AllergensImages {
  image: string;
  alt: string;
  id: number;
  component: React.ElementType;
  describe: string;
  description: string;
}

export const allergensArray = [
  {
    image: gluten,
    component: Gluten,
    alt: translatedName.gluten,
    describe: translatedName.gluten,
    description: translatedDescription.gluten,
    id: 1,
  },
  {
    image: eggs,
    component: Eggs,
    alt: translatedName.eggs,
    describe: translatedName.eggs,
    description: translatedDescription.eggs,
    id: 2,
  },
  {
    image: lactose,
    component: Lactose,
    alt: translatedName.lactose,
    describe: translatedName.lactose,
    description: translatedDescription.lactose,
    id: 3,
  },
  {
    image: fish,
    component: Fish,
    alt: translatedName.fish,
    describe: translatedName.fish,
    description: translatedDescription.fish,
    id: 4,
  },
  {
    image: sea,
    component: Sea,
    alt: translatedName.sea,
    describe: translatedName.sea,
    description: translatedDescription.fish,
    id: 5,
  },
  {
    image: shelfish,
    component: Shelfish,
    alt: translatedName.shellfish,
    describe: translatedName.shellfish,
    description: translatedDescription.shellfish,
    id: 6,
  },
  {
    image: nuts,
    component: Nuts,
    alt: translatedName.nuts,
    describe: translatedName.nuts,
    description: translatedDescription.nuts,
    id: 7,
  },
  {
    image: soy,
    component: Soy,
    alt: translatedName.soy,
    describe: translatedName.soy,
    description: translatedDescription.soy,
    id: 8,
  },
  {
    image: celer,
    component: Celer,
    alt: translatedName.celery,
    describe: translatedName.celery,
    description: translatedDescription.celery,
    id: 9,
  },
  {
    image: mustard,
    component: Mustart,
    alt: translatedName.mustard,
    describe: translatedName.mustard,
    description: translatedDescription.mustard,
    id: 10,
  },
  {
    image: sulfur,
    component: Sulfur,
    alt: translatedName.sulfur,
    describe: translatedName.sulfur,
    description: translatedDescription.sulfur,
    id: 11,
  },
  {
    image: seasame,
    component: Seasame,
    alt: translatedName.sesame,
    describe: translatedName.sesame,
    description: translatedDescription.sesame,
    id: 12,
  },
];
