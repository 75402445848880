export const cardDimensions = Object.freeze({
  width: "235px",
  height: "294px",
  widthBorder: "231px",
  heightBorder: "290px",
  borderRadius: "10px",
  smallWidth: "162px",
  smallHeight: "202px",
  smallWidthBorder: "160px",
  smallHeightBorder: "198px",
});
